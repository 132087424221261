import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import range from 'lodash/range';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import {
  ObservableMap,
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { createTransformer } from 'mobx-utils';
import { getDocumentBoundForm } from 'src/components/documents/get-document-data';
import {
  ALL_WIDGETS,
  OCR_TYPE_TO_ZONE_TYPE,
  RECIPIENT_DATA_RENDER_TYPES,
  TAB_FIELD_TYPES,
  TOGGLE_SIZES,
} from 'src/components/documents/pspdfkit/annotations/constants';
import logger from 'src/logger';
import { getPSPDFKit, initializePSPDFKit } from 'src/utils/pspdfkit';
import { fromPspdfkitType } from 'src/utils/pspdfkit-type';

export const {
  TRANSACTION_NAMESPACE,
  TRANSACTION_PACKAGE_NAMESPACE,
  PROPERTY_NAMESPACE,
} = window.Glide.CONSTANTS;

export const FIELD_OUT_SEP = '!';

// eslint-disable-next-line no-unused-vars
const ANNOTATION_COLORS = [
  '#F9CDCD',
  '#F6C7BC',
  '#FFE6D4',
  '#FFEBBD',
  '#D8EAAA',
  '#BCE0AC',
  '#A8D7CC',
  '#A0D4D9',
  '#B1C4E5',
  '#B9B5DE',
  '#DDCBBF',
  '#CFC0E5',
  '#DEBBE8',
  '#F1BDE0',
];

export const AUTOLINK_NAMESPACE = '$autolink';

export const FORM_NAMESPACE = 'form';

export const LINKABLE_NAMESPACES = [
  TRANSACTION_NAMESPACE,
  TRANSACTION_PACKAGE_NAMESPACE,
  FORM_NAMESPACE,
];

const MISSING_FIELDS_CONIDITONS_UDPATE_DELAY = 500;

const ANNOTATION_TEXT_PADDING = 2;

export default class PDFAnnotationsStore {
  @observable pspdfkitInstance;

  @observable currentLayer;

  @observable transaction;

  @observable formId;

  @observable permissions = [];

  @observable isDesignMode = true;

  @observable isFillMode = false;

  @observable isSignMode = false;

  @observable isPreviewDesignMode = false;

  @observable interactionMode = null;

  @observable isInconsistent = false;

  @observable selectedField;

  @observable selectedAnnotationsMap = new ObservableMap();

  @observable editedAnnotations = [];

  @observable allowedTypes = [];

  @observable clipboard = [];

  @observable recipientRole;

  @observable recipientColor;

  @observable recipient;

  @observable recipients = [];

  @observable insertOnClick = null;

  @observable annotationsLoaded = false;

  @observable allAnnotationsMap = new ObservableMap();

  @observable annotationsByFormFieldName = new ObservableMap();

  @observable clauseTarget = null;

  @observable feedbackFormTarget = null;

  @observable feedbackFormAction = null;

  @observable showLegalNameSettings = false;

  @observable toggleSize = 'NORMAL';

  @observable propertiesEnabled = false;

  @observable selectedMissingFillCondition = null;

  @observable missingFillConditionsMode = false;

  @observable missingFieldsFillConditionsCount = 0;

  @observable missingFieldsFillConditions = new ObservableMap();

  @observable highlighted = [];

  @observable groupByColor = true;

  @observable guidingTextVisible = true;

  @observable disableToa = false;

  @observable _boundForm = null;

  @observable canUndo = false;

  @observable canRedo = false;

  @observable componentForceUpdate = null;

  @observable hasUnsavedChanges = false;

  colorsBuffer = [];
  fieldColorMap = {};
  notifiedAnnotationDeletes = new Map();

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;

    /** This operation is expensive. Use delay to simulate the throttle lazy calculation. */
    autorun(
      () => {
        this.updateMissingFieldConditions();
      },
      {
        delay: MISSING_FIELDS_CONIDITONS_UDPATE_DELAY,
      }
    );
  }

  initialize = async () => {
    const { features } = this.parent;
    // Ensure that the LD Client was initialized before fetching the flag
    await features.isInitialized();
    // Load the appropriate PSPDFKit SDK based on the server version
    await initializePSPDFKit(features.pspdfkitVersion);
  };

  get boundForm() {
    return this._boundForm;
  }

  set boundForm(form) {
    this._boundForm = form;
  }

  @computed
  get allAnnotations() {
    return Array.from(this.allAnnotationsMap.values());
  }

  set allAnnotations(annotations) {
    this.allAnnotationsMap.replace(this.toAnnotationsMap(annotations));
    this.annotationsByFormFieldName.replace(
      this.toAnnotationsMap(
        annotations,
        'customData.formFieldName',
        (annotation1, annotation2) => {
          const val1 = parseInt(annotation1.customData?.index, 10);
          const val2 = parseInt(annotation2.customData?.index, 10);
          if (Number.isNaN(val1)) {
            return 1;
          }
          if (Number.isNaN(val2)) {
            return -1;
          }
          return val1 - val2;
        }
      )
    );
  }

  @computed
  get selectedAnnotations() {
    return Array.from(this.selectedAnnotationsMap.values());
  }

  set selectedAnnotations(annotations) {
    const annotationsIds = annotations.filter((a) => a).map(({ id }) => id);
    const selectedAnnotations = Array.from(
      this.allAnnotationsMap.values()
    ).filter(({ id }) => annotationsIds.includes(id));
    this.selectedAnnotationsMap.replace(
      this.toAnnotationsMap(selectedAnnotations)
    );
  }

  handleAnnotationHeight = async (annotations) => {
    const updatedAnnotations = annotations
      .map((annotation) => {
        const annotationTextNode =
          this.pspdfkitInstance.contentDocument.getElementById(
            `pdf-annotation-text-${annotation.id}`
          );

        if (!annotationTextNode) {
          return undefined;
        }

        if (
          annotationTextNode.clientHeight + ANNOTATION_TEXT_PADDING * 2 ===
          annotation.boundingBox.height
        ) {
          return undefined;
        }

        return annotation.set(
          'boundingBox',
          annotation.boundingBox.set(
            'height',
            annotationTextNode.clientHeight + ANNOTATION_TEXT_PADDING * 2
          )
        );
      })
      .filter(Boolean);
    this.pspdfkitInstance.history.disable();
    await this.pspdfkitInstance.update(updatedAnnotations);
    this.pspdfkitInstance.history.enable();
  };

  getAnnotationNewPoint = (annotation) => {
    const { width: pageWidth, height: pageHeight } =
      this.pspdfkitInstance.pageInfoForIndex(annotation.pageIndex);
    const { x, y } = annotation.boundingBox.getLocation();
    const { width: annotationWidth, height: annotationHeight } =
      annotation.boundingBox;

    const newPoint = {
      x: Math.max(
        x + annotationWidth > pageWidth ? pageWidth - annotationWidth : x,
        0
      ),
      y: Math.max(
        y + annotationHeight > pageHeight ? pageHeight - annotationHeight : y,
        0
      ),
    };

    return newPoint.x === x && newPoint.y === y
      ? null
      : new (getPSPDFKit().Geometry.Point)(newPoint);
  };

  handleTextAnnotationBoundary = (annotations) => {
    const newPointAnnotations = annotations
      .map((annotation) => {
        if (
          !this.pspdfkitInstance.contentDocument.getElementById(
            `pdf-annotation-text-${annotation.id}`
          )
        ) {
          return undefined;
        }

        const newPoint = this.getAnnotationNewPoint(annotation);
        return (
          newPoint &&
          annotation.set(
            'boundingBox',
            annotation.boundingBox.setLocation(newPoint)
          )
        );
      })
      .filter(Boolean);
    return this.pspdfkitInstance.update(newPointAnnotations);
  };

  @action
  setRenderOptions = (renderOptions) => {
    this.boundForm?.setRenderOptions(renderOptions);
  };

  @action
  async setPspdfkitInstance({
    pspdfkitInstance,
    formData = null,
    options = {},
    mode = 'design',
    groupByColor = true,
    guidingText = true,
    disableToa = false,
  }) {
    this.options = options || {};

    this.annotationsLoaded = false;

    this.pspdfkitInstance = pspdfkitInstance;
    this.setDesignMode(mode === 'design');
    this.setFillMode(mode === 'fill');
    this.setSignMode(mode === 'sign');
    this.resetSelectedAnnotations();
    this.setToggleSize('NORMAL');
    this.setGroupByColor(groupByColor);
    this.setGuidingTextVisibility(guidingText);
    this.toggleMissingFillConditionsMode(false);
    this.disableToa = disableToa;

    if (formData) {
      this.setBoundForm(formData);
    }

    await this.getFetchAnnotations(true);

    this.pspdfkitInstance.addEventListener(
      'annotations.create',
      async (annotations) => {
        const reindexedAnnotations = await this.reindexFields(annotations);

        if (!reindexedAnnotations.length) {
          // sometimes side-effects might cause the annotation to be present
          // already because this event got fired later.
          runInAction(() => {
            this.allAnnotations = [
              ...this.allAnnotations,
              ...annotations.filter(
                ({ id }) => !this.allAnnotations.find((a) => a.id === id)
              ),
            ];

            this.canUndo = this.pspdfkitInstance.history.canUndo();
            this.canRedo = this.pspdfkitInstance.history.canRedo();
            this.hasUnsavedChanges = this.pspdfkitInstance.hasUnsavedChanges();
          });
        }
      }
    );

    this.pspdfkitInstance.addEventListener(
      'annotations.update',
      async (annotations) => {
        const reindexedAnnotations = await this.reindexFields(annotations);

        if (!reindexedAnnotations.length) {
          // The font size change, the number of words increase, and the annotation resize may all affect the annotation height, so the annotation height is uniformly processed here
          // In addition, SetOnAnnotationResizeStart API cannot be used in the current pspdfkit version, cannot monitor the annotation resize in other place, so had to deal with here
          await this.handleAnnotationHeight(annotations);
          await this.handleTextAnnotationBoundary(annotations);

          const updatedIds = annotations.map(({ id }) => id);
          const selectedIds = this.selectedAnnotations.map(({ id }) => id);
          runInAction(() => {
            this.allAnnotations = [
              ...this.allAnnotations.filter(
                ({ id }) => !updatedIds.includes(id)
              ),
              ...annotations,
            ];
            this.selectedAnnotations = [
              ...this.selectedAnnotations.filter(
                ({ id }) => !updatedIds.includes(id)
              ),
              ...annotations.filter(({ id }) => selectedIds.includes(id)),
            ];
            this.canUndo = this.pspdfkitInstance.history.canUndo();
            this.canRedo = this.pspdfkitInstance.history.canRedo();
            this.hasUnsavedChanges = this.pspdfkitInstance.hasUnsavedChanges();
          });
        }
      }
    );

    this.pspdfkitInstance.addEventListener(
      'annotations.delete',
      async (annotations) => {
        const reindexedAnnotations = await this.reindexFields(annotations);

        if (!reindexedAnnotations.length) {
          const deletedIds = annotations.map(({ id }) => id);
          runInAction(() => {
            this.allAnnotations = [
              ...this.allAnnotations.filter(
                ({ id }) => !deletedIds.includes(id)
              ),
            ];
            this.canUndo = this.pspdfkitInstance.history.canUndo();
            this.canRedo = this.pspdfkitInstance.history.canRedo();
            this.hasUnsavedChanges = this.pspdfkitInstance.hasUnsavedChanges();
          });
        }

        const deletedIds = annotations.map(({ id }) => id);
        this.setSelectedAnnotations(
          this.selectedAnnotations
            .filter(Boolean)
            .filter(({ id }) => !deletedIds.includes(id))
        );

        this.componentForceUpdate?.();
      }
    );

    this.pspdfkitInstance.contentDocument.addEventListener(
      'keydown',
      (event) => {
        const { key = '' } = event;
        // NOTE: when only 1 annotation is selected, PSPDFKIT alread handles the events
        const operationsByKey = {
          ArrowUp: {
            diff: -1,
            attribute: 'y',
            limit: 'top',
          },
          ArrowDown: {
            diff: 1,
            attribute: 'y',
            sizeAttribute: 'height',
            limit: 'bottom',
          },
          ArrowLeft: {
            diff: -1,
            attribute: 'x',
            limit: 'left',
          },
          ArrowRight: {
            diff: 1,
            attribute: 'x',
            sizeAttribute: 'width',
            limit: 'right',
          },
        };
        if (
          this.selectedAnnotations.length > 1 &&
          Boolean(operationsByKey[key])
        ) {
          event.preventDefault();
          const operation = operationsByKey[key];

          const changes = this.selectedAnnotations.map((annotation) => {
            let diff = operation.diff;
            // Make sure the move doesn't place the annotation outside of the page limits
            const position = annotation.boundingBox[operation.limit];
            const newPosition = position + diff;
            if (operation.sizeAttribute) {
              const pageInfo = this.pspdfkitInstance.pageInfoForIndex(
                annotation.pageIndex
              );
              const pageSize = pageInfo[operation.sizeAttribute];
              if (newPosition > pageSize) {
                diff = pageSize - position;
              }
            } else if (newPosition < 0) {
              diff = -position;
            }

            const annotationLocation = annotation.boundingBox.getLocation();
            const nextLocation = annotationLocation.set(
              operation.attribute,
              annotationLocation[operation.attribute] + diff
            );
            const newBoundingBox =
              annotation.boundingBox.setLocation(nextLocation);
            return annotation.set('boundingBox', newBoundingBox);
          });
          this.pspdfkitInstance.update(changes);
        }
      }
    );
  }

  @action
  setComponentForceUpdate(componentForceUpdate) {
    this.componentForceUpdate = componentForceUpdate;
  }

  @action
  closeInstance() {
    this.setFillMode(false);
    this.setDesignMode(false);
    this.setSignMode(false);
    this.setPreviewDesignMode(false);
    this.toggleMissingFillConditionsMode(false);
    this.setSelectedField(null);
    this.resetSelectedAnnotations();
    this.toggleInteractionMode(null);
    this.canUndo = false;
    this.canRedo = false;
    this.hasUnsavedChanges = false;
    this.selectedMissingFillCondition = null;
  }

  @computed
  get fieldAnnotations() {
    return this.allAnnotations.filter((annotation) =>
      get(annotation, 'customData.formFieldName')
    );
  }

  @computed
  get annotationsByFieldId() {
    const res = new Map();
    this.fieldAnnotations.forEach((annotation) => {
      if (!res.has(annotation.customData.formFieldName)) {
        res.set(annotation.customData.formFieldName, []);
      }
      res.get(annotation.customData.formFieldName).push(annotation);
    });
    return res;
  }

  @computed
  get dependentAnnotationsByFieldId() {
    const res = new Map();
    this.fieldAnnotations.forEach((annotation) => {
      if (!res.has(annotation.customData.formFieldName)) {
        res.set(annotation.customData.formFieldName, []);
      }
    });

    this.fieldAnnotations.forEach((annotation) => {
      if (!res.has(annotation.customData.formFieldName)) {
        res.set(annotation.customData.formFieldName, []);
      }

      if (
        annotation.customData.formFieldLinkNamespace === FORM_NAMESPACE &&
        annotation.customData.formFieldLinkId
      ) {
        const dependeeFieldId = annotation.customData.formFieldLinkId
          .split(FIELD_OUT_SEP)[0]
          .replace('--autolinked', '');

        if (
          !this.annotationsByFieldId.has(dependeeFieldId) ||
          !this.annotationsByFieldId.get(dependeeFieldId).length
        ) {
          return;
        }

        if (!res.has(dependeeFieldId)) {
          res.set(dependeeFieldId, []);
        }
        res.get(dependeeFieldId).push(annotation.customData.formFieldName);
      }
    });
    return res;
  }

  toAnnotationsMap = (annotations, keyBy = 'id', list = false) => {
    return annotations.reduce((map, annotation) => {
      const val = get(annotation, keyBy);
      if (val) {
        if (list) {
          if (!map[val]) {
            map[val] = [annotation];
          } else if (typeof list === 'function') {
            // If list is a function, it holds the sorting criteria
            let idx = 0;
            while (idx < map[val].length) {
              if (list(annotation, map[val][idx]) < 0) {
                break;
              }
              idx += 1;
            }
            map[val].splice(idx, 0, annotation);
          } else {
            // Otherwise just keep original order
            map[val].push(annotation);
          }
        } else {
          map[val] = annotation;
        }
      }
      return map;
    }, {});
  };

  processAnnotations = (annotations) => {
    return annotations;
  };

  @action
  setCurrentLayer(layerName) {
    this.currentLayer = layerName;
  }

  @action
  setIsInconsistent(isInconsistent = false) {
    this.isInconsistent = isInconsistent;
  }

  @action
  setTransaction(transaction) {
    this.transaction = transaction;
    this.permissions = toJS(get(transaction, 'permissions', []));
  }

  @action
  setFormId(formId) {
    this.formId = formId;
  }

  @computed
  get isDirty() {
    return this.boundForm && this.boundForm.isDirty;
  }

  @action
  setBoundForm({ dependentForms, mainNamespace, mainForm, renderOptions }) {
    this.boundForm = getDocumentBoundForm({
      dependentForms,
      mainNamespace,
      mainForm,
      renderOptions,
    });
  }

  @action
  copySelectionToClipboard() {
    this.clipboard = [...this.selectedAnnotations];
  }

  @action
  addSelectedAnnotation(annotation) {
    this.selectedAnnotations = [
      ...this.selectedAnnotations.filter(({ id }) => id !== annotation.id),
      this.getAnnotationById(annotation.id),
    ];
  }

  @action
  removeSelectedAnnotation(annotation) {
    this.selectedAnnotations = (this.selectedAnnotations || []).filter(
      ({ id }) => id !== annotation.id
    );
  }

  @action
  ownAnnotationDeleteEnabled() {
    return (
      this.selectedAnnotations.length > 1 ||
      (this.selectedAnnotations.length &&
        ['pdf-redact-text', 'pdf-stamp', 'pdf-strikeout-text'].includes(
          get(this.selectedAnnotations[0], 'customData.type')
        ))
    );
  }

  @action
  setSelectedAnnotations(arr = []) {
    const currentAnnotations = arr.map(({ id }) => {
      return this.allAnnotations.find((annotation) => annotation.id === id);
    });
    this.selectedAnnotations = currentAnnotations;
    this.showProperties();
  }

  @action
  selectSingleAnnotation(annotation) {
    this.hideProperties();
    const current = this.allAnnotations.find((a) => a.id === annotation.id);
    this.selectedAnnotations = [current];
  }

  @action
  selectByName(name) {
    const byName = this.allAnnotations.filter((a) => {
      const n = get(a, 'customData.name');
      return n && name === n;
    });
    this.selectedAnnotations = byName;
  }

  @action
  resetSelectedAnnotations() {
    this.selectedAnnotations = [];
    if (
      this.pspdfkitInstance &&
      this.pspdfkitInstance.getSelectedAnnotation()
    ) {
      this.pspdfkitInstance.setSelectedAnnotation(null);
    }
  }

  @action
  addEditedAnnotations(annotation) {
    this.editedAnnotations = [
      ...this.editedAnnotations.filter(({ id }) => id !== annotation.id),
      this.getAnnotationById(annotation.id),
    ];
  }

  @action
  resetEditedAnnotations() {
    this.editedAnnotations = [];
  }

  @action
  setSelectedField(field) {
    this.selectedField = field;
  }

  @action
  deleteFieldTypeAnnotations = async () => {
    const annotationsToDelete = this.allAnnotations.filter(
      (a) =>
        TAB_FIELD_TYPES.includes(a.customData.type) &&
        a.customData.source === 'auto'
    );
    await this.pspdfkitInstance.delete(annotationsToDelete.map(({ id }) => id));
    this.setSelectedAnnotations([]);
  };

  getWithinRect(rect, page) {
    return this.allAnnotations
      .filter((a) => a.pageIndex === page)
      .filter(({ boundingBox }) => rect.isRectInside(boundingBox));
  }

  getAnnotationIsOverlapping({ id, boundingBox, pageIndex }, filter) {
    return this.allAnnotations
      .filter((a) => a.pageIndex === pageIndex && a.id !== id)
      .filter((a) => {
        if (typeof filter === 'function') {
          return filter(a);
        }
        return true;
      })
      .filter((a) => a.boundingBox.isRectOverlapping(boundingBox))
      .some((overlappingAnnotation) => {
        if (
          overlappingAnnotation.boundingBox.isRectInside(boundingBox) ||
          boundingBox.isRectInside(overlappingAnnotation.boundingBox)
        ) {
          return true;
        }

        const tolerance = 5;
        // pspdfkit should be able to do this, but for some reason
        // the built in isRectInside does not work on bounding boxes
        // that were "grown".
        const isInside = (a, b) => {
          return (
            a.top > b.top &&
            a.left > b.left &&
            a.bottom < b.bottom &&
            a.right < b.right
          );
        };
        return (
          isInside(
            boundingBox.grow(tolerance * -1),
            overlappingAnnotation.boundingBox
          ) ||
          isInside(
            overlappingAnnotation.boundingBox.grow(tolerance * -1),
            boundingBox
          )
        );
      });
  }

  getOverlappingAnnotations(filter) {
    return this.allAnnotations
      .filter((annotation) => {
        if (typeof filter === 'function') {
          return filter(annotation);
        }
        return true;
      })
      .filter((annotation) => {
        return this.getAnnotationIsOverlapping(annotation, filter);
      });
  }

  @action
  setDesignMode(value) {
    this.isDesignMode = value;
  }

  @action
  toggleDesignMode() {
    this.isDesignMode = !this.isDesignMode;
  }

  @action
  setFillMode(value) {
    this.isFillMode = value;
  }

  @action
  toggleFillMode() {
    this.isFillMode = !this.isFillMode;
  }

  @action
  setSignMode(value) {
    this.isSignMode = value;
  }

  @action
  toggleSignMode() {
    this.isSignMode = !this.isSignMode;
  }

  @action
  setPreviewDesignMode(value) {
    this.isPreviewDesignMode = value;
  }

  @action
  setRecipientRole(value) {
    this.recipientRole = value;
  }

  @action
  setRecipientColor(value) {
    this.recipientColor = value;
  }

  @action
  setRecipient(recipient) {
    this.recipient = recipient;
  }

  @action
  setRecipients(value) {
    this.recipients = value;

    if (value.length) {
      this.recipientRole =
        value.reduce((role, recipient) => {
          return (
            role ||
            get(
              recipient,
              'party.roles.0',
              // Account for other contact roles
              'userContact' in recipient &&
                recipient.userContact.roles.indexOf('OTHER') >= 0
                ? 'OTHER'
                : null
            )
          );
        }, null) || 'PLACEHOLDER1';
    }
  }

  @action
  updateAnnotationDefaultValueFromRecipient(annotation, recipient) {
    if (!annotation?.customData?.type || !recipient) {
      return;
    }

    const type = annotation.customData.type;
    const defaultValueFromRecipient =
      ALL_WIDGETS[type]?.defaultValueFromRecipient;
    if (!defaultValueFromRecipient) {
      return;
    }

    annotation.customData.defaultValue = defaultValueFromRecipient(recipient);
    this.addEditedAnnotations(annotation);
  }

  @action
  updateRecipientFields(recipients) {
    const annotations = this?.getRecipientDataAnnotations();
    if (!annotations || annotations.length === 0) {
      return;
    }

    annotations.forEach((annotation) => {
      const recipient = recipients?.find(
        (r) => r.key === annotation?.customData?.formFieldOwnerId
      );
      if (!recipient) {
        return;
      }
      this.updateAnnotationDefaultValueFromRecipient(annotation, recipient);
    });
  }

  @action
  setInsertOnClick(value) {
    this.insertOnClick = value;
  }

  @action
  setAllowedTypes(allowedTypes = []) {
    this.allowedTypes = allowedTypes;
  }

  @action
  setGroupByColor(value) {
    this.groupByColor = Boolean(value);
  }

  @action
  setGuidingTextVisibility(value) {
    this.guidingTextVisibility = Boolean(value);
  }

  getIsExcluded(annotation) {
    if (!annotation.customData && this.isDesignMode) {
      return false;
    }
    const allowed = this.allowedTypes ?? [];
    const { type = '', ocrEnabled = false } = annotation.customData;
    const ocrAltType = (ocrEnabled && OCR_TYPE_TO_ZONE_TYPE[type]) || '';
    return ![type, ocrAltType].find((t) =>
      allowed.includes(fromPspdfkitType(t))
    );
  }

  getPdfValue(annotation) {
    const customData = annotation?.customData ?? false;

    if (!this.annotationsLoaded || !this.boundForm || !customData) {
      return undefined;
    }

    const boundOutput = this.getBoundOutputByFieldId(customData.formFieldName);
    if (!boundOutput || boundOutput.isNa) {
      return undefined;
    }

    return boundOutput.delegateOutput.pdfValue;
  }

  getIsLinked(annotation) {
    const customData = get(annotation, 'customData', false);

    if (!this.boundForm || !customData) {
      return undefined;
    }

    const boundOutput = this.getBoundOutputByFieldId(customData.formFieldName);
    return boundOutput && boundOutput.isLinked;
  }

  getValue(annotation) {
    return this.getPdfValue(annotation);
  }

  @computed
  get valueOf() {
    return createTransformer((annotation) => {
      return this.getValue(annotation);
    });
  }

  @computed
  get isLinked() {
    return createTransformer((annotation) => {
      // Returns:
      //   true  - Field is linked
      //   false - Field was unlinked
      //   undefined - Field is not linked
      return this.getIsLinked(annotation);
    });
  }

  isReadOnly(annotation) {
    if (!this.isFillMode && !this.isSignMode) {
      return false;
    }

    const boundOutput = this.getBoundOutput(annotation);
    return !boundOutput || !boundOutput.canSetValue(this.permissions);
  }

  @computed
  get isComputedOnly() {
    return createTransformer((annotation) => {
      if (!this.isFillMode) {
        return false;
      }
      const boundOutput = this.getBoundOutput(annotation);
      return Boolean(boundOutput && boundOutput.isComputedOnly);
    });
  }

  @computed
  get conditionedFields() {
    return createTransformer((fieldName) => {
      return this.allAnnotations
        .filter(({ customData }) => !!customData)
        .filter(({ customData }) => {
          return (get(customData, 'conditionalTerms') || []).includes(
            fieldName
          );
        })
        .sort((a, b) => a.customData.index - b.customData.index);
    });
  }

  @computed
  get linkedAnnotations() {
    return createTransformer((annotationOrFieldName) => {
      const formFieldName =
        typeof annotationOrFieldName === 'string'
          ? annotationOrFieldName
          : annotationOrFieldName?.customData?.formFieldName;

      if (!formFieldName) {
        return [];
      }

      return this.allAnnotations
        .filter(({ customData }) => {
          return (
            (customData?.formFieldLinkId ?? '').startsWith(formFieldName) &&
            customData?.formFieldLinkNamespace === FORM_NAMESPACE
          );
        })
        .sort((a, b) => a.customData.index - b.customData.index);
    });
  }

  getNextFillableAnnotation(currentAnnotation = null, backwards = false) {
    if (!this.allAnnotations.length || this.focusableFormFields.length <= 1) {
      return null;
    }

    const formFields = backwards
      ? [...this.focusableFormFields].reverse()
      : this.focusableFormFields;
    let currentIndex = -1;

    if (currentAnnotation !== null) {
      currentIndex = findIndex(formFields, (formFieldName) => {
        return formFieldName === currentAnnotation.customData.formFieldName;
      });
    }
    if (
      currentIndex === formFields.length - 1 ||
      !formFields[currentIndex + 1]
    ) {
      currentIndex = -1;
    }

    // get the form field parts and focus on the first annotation in it
    const nextFieldParts = this.getAnnotationsByFormField(
      formFields[currentIndex + 1]
    );
    return nextFieldParts[0];
  }

  @action
  setValues(values) {
    toPairs(values).forEach(([fieldId, value]) => {
      const { topLinkedOutput } = this.getBoundOutputByFieldId(fieldId);
      topLinkedOutput.setFields({
        [topLinkedOutput.identityFieldName]: value,
      });
    });
  }

  getBoundOutputByFieldId(fieldId) {
    return this.boundForm && this.boundForm.getBoundOutputForField(fieldId);
  }

  getBoundOutput(annotation) {
    return this.getBoundOutputByFieldId(annotation.customData.formFieldName);
  }

  @action
  setValue(annotation, value) {
    if (!!annotation && !!annotation.customData) {
      this.setValues({
        [annotation.customData.formFieldName]: value,
      });
    }
  }

  getStaged() {
    return this.boundForm && this.boundForm.getStaged();
  }

  getStagedUnlinkedIds() {
    return this.boundForm && this.boundForm.getStagedUnlinkedIds();
  }

  getStagedLinkedIds() {
    return this.boundForm && this.boundForm.getStagedLinkedIds();
  }

  @computed
  get isClean() {
    return !this.isDirty;
  }

  @action unstage = () => this.boundForm.unstage();

  @computed
  get hasAnnotations() {
    return (
      this.annotationsLoaded &&
      !!this.allAnnotations &&
      !!this.allAnnotations.length
    );
  }

  getAnnotationById(annotationId) {
    return this.allAnnotations.find(({ id }) => id === annotationId);
  }

  getAnnotationIndexById(annotationId) {
    return this.allAnnotations.findIndex(({ id }) => id === annotationId);
  }

  getAnnotationByName(name) {
    return this.allAnnotations.find((a) => a.name === name);
  }

  @computed
  get focusableFormFields() {
    return uniq(
      this.allAnnotations
        .filter((annotation) => {
          try {
            const boundOutput = this.getBoundOutput(annotation);
            return boundOutput && boundOutput.canSetValue(this.permissions);
          } catch (err) {
            // fail silently if we couldn't get the output, but
            // keep on building the list anyway so the form
            // remains usable
            return true;
          }
        })
        .map(({ customData }) => customData && customData.formFieldName)
    ).filter((formFieldName) => formFieldName !== false);
  }

  getAnnotations() {
    const { includeAnnotationTypes } = this.options;
    return this.allAnnotations.filter((annotation) => {
      return (
        !includeAnnotationTypes ||
        includeAnnotationTypes
          .map((type) => type.toLowerCase())
          .includes(get(annotation, 'customData.type', '').toLowerCase())
      );
    });
  }

  async getFetchAnnotations(forceFetch = false) {
    if (!this.pspdfkitInstance) {
      runInAction(() => {
        this.allAnnotations = [];
      });
      return this.allAnnotations;
    }

    if (!!this.allAnnotations.length && forceFetch) {
      runInAction(() => {
        this.allAnnotations = [];
      });
    }

    if (!this.allAnnotations.length || forceFetch) {
      const all = (
        await Promise.all(
          [...Array(this.pspdfkitInstance.totalPageCount).keys()].map(
            (pageIndex) => this.pspdfkitInstance.getAnnotations(pageIndex)
          )
        )
      ).reduce((accum, annos) => accum.concat(...annos), []);
      runInAction(() => {
        this.allAnnotations = this.processAnnotations(
          all.sort((a, b) => {
            if (a.pageIndex !== b.pageIndex) {
              return a.pageIndex - b.pageIndex;
            }

            // keep them sorted left to right, top to bottom.
            // `top` has a small tolerance in case the manually placed annotations are
            // off by a couple of pixels, in which case we still want to consider
            // them on the same row
            return Math.abs(a.boundingBox.top - b.boundingBox.top) < 5
              ? a.boundingBox.left - b.boundingBox.left
              : a.boundingBox.top - b.boundingBox.top;
          })
        );

        this.annotationsLoaded = true;
      });
    }

    return this.allAnnotations;
  }

  getAnnotationsByCustomDataFieldValue(values_, fieldKey) {
    // TODO index allAnnotations by needed fields
    const values = values_.filter((v) => v?.trim());
    if (!values.length) {
      return [];
    }
    const set = new Set(values);
    return this.allAnnotations.filter((a) =>
      set.has((a.customData || {})[fieldKey])
    );
  }

  getAnnotationsByFormFieldNames(...formFieldNames) {
    return this.getAnnotationsFromMapByFormFieldNames(
      this.annotationsByFormFieldName,
      formFieldNames
    );
  }

  getAnnotationsFromMapByFormFieldNames(annotationMap, formFieldNames) {
    const res = [];
    const seen = new Set();
    formFieldNames.forEach((formFieldName) => {
      if (!seen.has(formFieldName)) {
        seen.add(formFieldName);
        const annotations = annotationMap.get(formFieldName);
        if (annotations?.length) {
          res.push(...annotations);
        }
      }
    });
    return res;
  }

  getAnnotationsByFormField(annotationOrFieldName, currentPage = false) {
    const formFieldName =
      typeof annotationOrFieldName === 'string'
        ? annotationOrFieldName
        : annotationOrFieldName.customData?.formFieldName;

    const annotation =
      typeof annotationOrFieldName !== 'string' ? annotationOrFieldName : null;

    const res = this.getAnnotationsByFormFieldNames(formFieldName);

    return annotation && currentPage !== false
      ? res.filter(({ pageIndex }) => pageIndex === annotation.pageIndex)
      : res;
  }

  getRecipientDataAnnotations() {
    return this.allAnnotations.filter((a) =>
      RECIPIENT_DATA_RENDER_TYPES.includes(a?.customData?.type)
    );
  }

  getAnnotationsByNames(...names) {
    return this.getAnnotationsByCustomDataFieldValue(names, 'name');
  }

  get currentZoomLevel() {
    return this.pspdfkitInstance.currentZoomLevel;
  }

  getPageWidth = (pageIndex) =>
    this.pspdfkitInstance?.pageInfoForIndex(pageIndex)?.width ?? 0;

  getPageOffset = (pageIndex) => {
    const zoom = this.currentZoomLevel;
    const PAGE_GUTTER = 20 * zoom; // Value got experimentally, not guaranteed to work every time
    let offset = PAGE_GUTTER;
    range(pageIndex).forEach((idx) => {
      offset +=
        this.pspdfkitInstance.pageInfoForIndex(idx).height * zoom + PAGE_GUTTER;
    });
    return offset;
  };

  getAnnotationsOffset = (annotations_, { position = 'top' } = {}) => {
    // position = 'center' doesn't work with multi page annotations (just takes the ones from the first included page)
    // This could also return the zoom level needed to ensure all of them fit the screen

    if (!annotations_.length) {
      return 0;
    }

    const pageIndexFunc = position === 'bottom' ? Math.max : Math.min;
    const pageIndex =
      pageIndexFunc(...annotations_.map((a) => a.pageIndex)) || 0;

    const annotations = annotations_.filter((a) => a.pageIndex === pageIndex);

    const zoom = this.currentZoomLevel;
    const top = Math.min(...annotations.map((a) => a.boundingBox.top)) * zoom;
    const bottom =
      Math.max(...annotations.map((a) => a.boundingBox.bottom)) * zoom;
    const offsetInPage = {
      top,
      center: top + (bottom - top) / 2,
      bottom,
    }[position];

    return this.getPageOffset(pageIndex) + offsetInPage;
  };
  getAnnotationOffset = (annotation, ...args) =>
    this.getAnnotationsOffset([annotation], ...args);

  get psPdfKitScroll() {
    return this.pspdfkitInstance.contentDocument.querySelector(
      '.PSPDFKit-Scroll'
    );
  }

  scrollTo = (
    y,
    { smooth = true, placement = 'top', minScrollLength = 0, callback } = {}
  ) => {
    const { psPdfKitScroll } = this;
    const extraOffset = {
      top: 0,
      center: -(psPdfKitScroll.clientHeight / 2),
      bottom: -psPdfKitScroll.clientHeight,
    }[placement];
    const top = Math.max(0, y + extraOffset);
    if (
      Math.abs(top - psPdfKitScroll.scrollTop) >=
      Math.max(minScrollLength || 0, 0.1)
    ) {
      if (callback) {
        const cb = () => {
          callback();
          psPdfKitScroll.removeEventListener('scroll', cb);
        };
        psPdfKitScroll.addEventListener('scroll', cb);
      }
      psPdfKitScroll.scrollTo({
        top,
        behavior: smooth ? 'smooth' : undefined,
      });
    } else if (callback) {
      // If scroll is avoided because of minScrollLength, callback still has to be called (immediately)
      callback();
    }
  };

  getOffset(offset) {
    if (!offset) {
      return 0;
    }
    if (
      typeof offset === 'string' &&
      offset.endsWith('%') &&
      !Number.isNaN(window.parseFloat(offset.slice(0, -1)))
    ) {
      const { psPdfKitScroll } = this;
      return (
        (psPdfKitScroll.clientHeight *
          window.parseFloat(offset.slice(0, -1), 0)) /
        100.0
      );
    }
    const res = window.parseFloat(offset);
    return !Number.isNaN(res) ? res : 0;
  }

  jumpToAnnotations(
    annotations,
    {
      blocking = false,
      placement = 'center',
      minScrollLength = 0,
      offset = 0,
      callback,
    } = {}
  ) {
    if (annotations?.length) {
      const handler = () => {
        this.scrollTo(
          this.getAnnotationsOffset(annotations, {
            position: placement,
          }) + this.getOffset(offset),
          {
            placement,
            minScrollLength,
            callback,
          }
        );
      };
      if (blocking) {
        handler();
      } else {
        setTimeout(handler, 0);
      }
    }
  }
  jumpToAnnotation = (annotation, ...args) =>
    this.jumpToAnnotations([annotation], ...args);

  getNextAvailableColor() {
    return (
      this.colorsBuffer.pop() ||
      (() => {
        const color = (this.colorsBuffer = [...ANNOTATION_COLORS]).pop();
        return color;
      })()
    );
  }

  getFormFieldColor(formFieldName) {
    if (!this.fieldColorMap[formFieldName]) {
      this.fieldColorMap[formFieldName] = this.getNextAvailableColor();
    }
    return this.fieldColorMap[formFieldName];
  }

  @action
  setClauseTarget(annotation) {
    this.clauseTarget = annotation;
  }

  isClauseTarget(annotation) {
    return this.clauseTarget && this.clauseTarget.id === annotation.id;
  }

  @action
  insertClause(clause) {
    if (!this.clauseTarget) {
      return;
    }

    if (this.isLinked(this.clauseTarget)) {
      this.getBoundOutput(this.clauseTarget).setIsUnlinked(true);
    }

    this.setValue(
      this.clauseTarget,
      `${this.getPdfValue(this.clauseTarget) || ''}${clause.description}`
    );

    this.setClauseTarget(null);
  }

  @action
  setFeedbackForm(feedbackFormTarget, feedbackFormAction = null) {
    this.feedbackFormTarget = feedbackFormTarget;
    this.feedbackFormAction = feedbackFormAction;
  }

  @action.bound
  resetFeedbackForm() {
    this.feedbackFormTarget = null;
    this.feedbackFormAction = null;
  }

  @action
  openLegalNameSettings() {
    this.showLegalNameSettings = true;
  }

  @action
  hideLegalNameSettings() {
    this.showLegalNameSettings = false;
  }

  @action
  setToggleSize(toggleSize) {
    if (Object.keys(TOGGLE_SIZES).includes(toggleSize)) {
      this.toggleSize = toggleSize;
    } else {
      this.toggleSize = 'NORMAL';
    }
  }

  @action
  hideProperties() {
    this.propertiesEnabled = false;
  }

  @action
  showProperties() {
    this.propertiesEnabled = true;
  }

  toggleTextSelection = (activate = false) => {
    const events = activate ? 'all' : 'none';
    const pageNodes =
      this.pspdfkitInstance.contentDocument.querySelectorAll('.PSPDFKit-Page');
    pageNodes.forEach((pageNode) => {
      const thirdLineDivs = pageNode.querySelectorAll(
        '.PSPDFKit-Page > div > div > div'
      );
      thirdLineDivs.forEach((node) => {
        if (!node.className) {
          return;
        }
        node.style.cssText += `pointer-events: ${events} !important`;
      });
    });
  };

  @computed
  get isRedactTextMode() {
    return this.interactionMode === 'pdf-redact-text';
  }

  @computed
  get isStrikeoutTextMode() {
    return this.interactionMode === 'pdf-strikeout-text';
  }

  @action
  toggleInteractionMode(mode) {
    this.resetSelectedAnnotations();
    if (this.interactionMode === mode) {
      this.interactionMode = null;
      return;
    }
    this.interactionMode = mode;
  }

  @action
  toggleRedactMode() {
    if (!this.pspdfkitInstance) {
      return;
    }
    this.toggleTextSelection(!this.isRedactTextMode);
    this.toggleInteractionMode('pdf-redact-text');
  }

  @action
  toggleStrikeoutTextMode() {
    if (!this.pspdfkitInstance) {
      return;
    }
    this.toggleInteractionMode('pdf-strikeout-text');
    this.toggleTextSelection(this.isStrikeoutTextMode);
  }

  getMissingFieldsFillConditions() {
    const missingConditions = [];
    const seenFieldNames = new Set();
    if (!this.isFillMode || !this.boundForm) {
      return missingConditions;
    }
    this.fieldAnnotations.forEach((annotation) => {
      const fieldName = annotation.customData.formFieldName;
      if (seenFieldNames.has(fieldName)) {
        // avoid duplication for multiple annotations of same field
        return;
      }
      const boundField = this.boundForm.getBoundFieldById(fieldName);
      if (!boundField) {
        logger.error(
          `failed to obtain boundField for field annotation ${fieldName}`
        );
        return;
      }
      let fieldLabel = annotation.customData.formFieldLabel;
      if (fieldLabel === fieldName) {
        // not user friendly label
        fieldLabel = boundField.userFriendlyLabel;
      }
      const fieldConditions = boundField.missingFillConditions.map((cond) => ({
        ...cond,
        annotationId: annotation.id,
        pageIndex: annotation.pageIndex + 1,
        fieldName,
        label: fieldLabel,
      }));
      missingConditions.push(...fieldConditions);
      seenFieldNames.add(fieldName);
    });
    return missingConditions;
  }

  missingFieldsFillConditionEquals(mffc1, mffc2) {
    // Returns true if 2 missingFieldsFillCondition objects are the equal
    return (
      Boolean(mffc1) === Boolean(mffc2) &&
      (!mffc1 ||
        (mffc1.id === mffc2.id &&
          mffc1.message === mffc2.message &&
          mffc1.terms.sort().join('-') === mffc2.terms.sort().join('-')))
    );
  }

  updateMissingFieldConditionsObservable = debounce(() => {
    setTimeout(() => {
      runInAction(() => {
        const setFieldNames = new Set();
        (this.missingFieldsFillConditions_ || []).forEach(
          (missingFieldsFillCondition) => {
            if (
              !this.missingFieldsFillConditionEquals(
                missingFieldsFillCondition,
                this.missingFieldsFillConditions.get(
                  missingFieldsFillCondition.fieldName
                )
              )
            ) {
              this.missingFieldsFillConditions.set(
                missingFieldsFillCondition.fieldName,
                missingFieldsFillCondition
              );
            }
            setFieldNames.add(missingFieldsFillCondition.fieldName);
          }
        );
        this.missingFieldsFillConditionsCount = setFieldNames.size;
        for (const fieldName of this.missingFieldsFillConditions.keys()) {
          if (
            !setFieldNames.has(fieldName) &&
            this.missingFieldsFillConditions.get(fieldName)
          ) {
            this.missingFieldsFillConditions.set(fieldName, null);
          }
        }
      });
    }, 0);
  }, MISSING_FIELDS_CONIDITONS_UDPATE_DELAY);

  updateMissingFieldConditions = () => {
    this.missingFieldsFillConditions_ = this.getMissingFieldsFillConditions();
    this.updateMissingFieldConditionsObservable();
  };

  @action
  selectMissingFillCondition(fieldName) {
    if (this.selectedMissingFillCondition?.fieldName === fieldName) {
      this.selectedMissingFillCondition = null;
      return;
    }
    this.selectedMissingFillCondition =
      this.missingFieldsFillConditions.get(fieldName);
    if (!this.selectedMissingFillCondition) {
      return;
    }
    const annotations = this.getAnnotationsByFormFieldNames(fieldName);
    this.jumpToAnnotations(annotations);
  }

  isMissingField = (fieldName) =>
    Boolean(fieldName) &&
    this.isFillMode &&
    this.missingFillConditionsMode &&
    Boolean(this.missingFieldsFillConditions.get(fieldName));

  @action
  toggleMissingFillConditionsMode(active) {
    this.missingFillConditionsMode = active;
  }

  @action
  setHighlighted(annotations, clearExisting = true) {
    const highlighted = (clearExisting ? [] : this.highlighted).concat(
      (annotations || []).map((a) => a.id)
    );
    this.highlighted = Array.from(new Set(highlighted));
  }

  @action
  clearHighlighted(annotations) {
    const clear = new Set((annotations || []).map((a) => a.id));
    this.highlighted = this.highlighted.filter((aId) => !clear.has(aId));
  }

  clearAllHighlighted() {
    this.setHighlighted([], true);
  }

  areHighlighted(annotations) {
    const highlighted = new Set(this.highlighted || []);
    return (
      Boolean(annotations?.length) &&
      annotations.every((a) => highlighted.has(a.id))
    );
  }

  isHighlighted = (annotation) => this.areHighlighted([annotation]);

  get pageCount() {
    return this.pspdfkitInstance.totalPageCount;
  }

  get currentPageIndex() {
    return this.pspdfkitInstance.viewState.currentPageIndex;
  }

  setAnnotationsToDelete(annotations, destinationPage) {
    annotations.forEach(({ id }) =>
      this.notifiedAnnotationDeletes.set(id, destinationPage)
    );
  }

  containsAnnotationSetToDelete(annotations) {
    const size = annotations.length || annotations.size || 0;
    return (
      size > 0 &&
      Boolean(
        annotations
          .map(({ id }) => this.notifiedAnnotationDeletes.has(id))
          .find(Boolean)
      )
    );
  }

  getAnnotationDestinationPage(annotation) {
    return this.notifiedAnnotationDeletes.get(annotation.id);
  }

  clearAnnotationsToDelete(annotations) {
    annotations.forEach(({ id }) => this.notifiedAnnotationDeletes.delete(id));
  }

  async saveToPspdfkit(timeout = null) {
    if (!this.pspdfkitInstance) {
      return null;
    }

    return new Promise((resolve, reject) => {
      const t =
        timeout !== null
          ? setTimeout(() => {
              reject(new Error('PSPDFKit timeout'));
            }, 10000)
          : null;

      this.pspdfkitInstance
        .save()
        .then((...res) => {
          clearTimeout(t);
          runInAction(() => {
            this.canUndo = this.pspdfkitInstance.history.canUndo();
            this.canRedo = this.pspdfkitInstance.history.canRedo();
            this.hasUnsavedChanges = this.pspdfkitInstance.hasUnsavedChanges();
          });
          resolve(...res);
        })
        .catch((err) => {
          clearTimeout(t);
          reject(err);
        });
    });
  }

  @action
  async undo() {
    if (!this.pspdfkitInstance) {
      return;
    }
    await this.pspdfkitInstance.history.undo();
  }

  @action
  async redo() {
    if (!this.pspdfkitInstance) {
      return;
    }

    await this.pspdfkitInstance.history.redo();
  }

  async reindexFields(_annotations = []) {
    // Introducing unwanted side effect back into form Editor
    // -> Always refetching to get 'natural' order from pspdfkit
    // Then use a Map that is not sorted by annotation index, but
    // just using the 'natural' order provided by pspdfkit.
    // To disable this, just use the values from updatedAnnotations if provided
    let annotations = await this.getFetchAnnotations(true);

    const updatedAnnotations =
      _annotations.size != null ? _annotations.toJS() : _annotations;
    if (updatedAnnotations.length) {
      const updatedFieldNames = updatedAnnotations.map(
        (a) => a.customData.formFieldName
      );
      annotations = annotations.filter((a) =>
        updatedFieldNames.includes(a.customData.formFieldName)
      );
    }

    const annotationMapSource = this.toAnnotationsMap(
      annotations,
      'customData.formFieldName',
      true
    );
    const annotationMap = new Map(Object.entries(annotationMapSource));
    const annotationGroups = Object.values(
      groupBy(
        this.getAnnotationsFromMapByFormFieldNames(
          annotationMap,
          annotations
            .filter(
              (a) =>
                a.customData?.formFieldName &&
                ['text', 'radio'].includes(a.customData?.type)
            )
            .map((a) => a.customData.formFieldName)
        ),
        'customData.formFieldName'
      )
    );

    const reindexedAnnotations = annotationGroups
      .map((group) => {
        return group.map((annotation, index) => {
          if (
            index === annotation?.customData.index ||
            !annotation.customData?.formFieldName
          ) {
            return null;
          }
          const newCustomData = { ...annotation.customData, index };
          if (annotation.customData.type === 'radio') {
            newCustomData.exportValue = `${index}`;
            if (!isNil(annotation.customData.defaultValue)) {
              newCustomData.defaultValue = null;
            }
          }

          return annotation.set('customData', newCustomData);
        });
      })
      .flat()
      .filter(Boolean);

    if (reindexedAnnotations.length) {
      this.pspdfkitInstance.history.disable();
      const res = await this.pspdfkitInstance.update(reindexedAnnotations);
      this.pspdfkitInstance.history.enable();
      runInAction(() => {
        this.canUndo = this.pspdfkitInstance.history.canUndo();
        this.canRedo = this.pspdfkitInstance.history.canRedo();
        this.hasUnsavedChanges = this.pspdfkitInstance.hasUnsavedChanges();
      });
      return res;
    }

    return [];
  }

  searchAnnotations(keyword = '') {
    if (!keyword || keyword?.length < 3) {
      return;
    }

    const matches = this.allAnnotations.filter(({ customData }) => {
      const searchIn = [
        'formFieldName',
        'formFieldLabel',
        'formFieldNum',
        'formFieldLinkNamespace',
        'formFieldLinkId',
      ];
      return searchIn.reduce((m, key) => {
        return (
          m ||
          (customData?.[key] ?? '')
            .toUpperCase()
            .indexOf(keyword.toUpperCase()) >= 0
        );
      }, false);
    });

    if (matches.length) {
      this.setSelectedAnnotations(matches);
      this.pspdfkitInstance.setSelectedAnnotation(null);
    }

    if (matches.length === 1) {
      this.pspdfkitInstance.setSelectedAnnotation(matches.pop());
    }
  }

  hasConditionLoops() {
    const hasCycle = (graph, name, path = []) => {
      if (path.includes(name)) {
        const nodes = path.filter(Boolean);
        const idx = nodes.findLastIndex((n) => n === name);
        const shortPath = ['', ...nodes.slice(idx), name];
        throw Error(
          `Cycle detected in NA conditions between the following Annotations:${shortPath.join(
            '\r\n -> '
          )}`
        );
      }
      return (graph?.[name]?.children ?? []).some((c) =>
        hasCycle(graph, c, [...path, name])
      );
    };
    const anyCycles = (graph) =>
      Object.keys(graph).some((k) => hasCycle(graph, k));

    const graph = this.allAnnotations
      .map((a) => a.customData)
      .reduce(
        (acc, v) => ({
          ...acc,
          [v.formFieldName]: { children: v.formFieldConditionTerms || [] },
        }),
        {}
      );

    try {
      anyCycles(graph);
      return { hasLoops: false };
    } catch (error) {
      return { hasLoops: true, message: error.message };
    }
  }
}
