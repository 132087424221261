

import React from 'react';
import { configure as configureTmModalLoader } from '@uc-tm/modal-loader';
import type { ToastVariant } from '@uc/compass-app-bridge/dist/actions';
import { Modal, ModalFuncProps, notification } from 'antd';
import type { ArgsProps } from 'antd/lib/notification';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import throttle from 'lodash/throttle';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { Provider } from 'mobx-react';
import api from 'src/api';
import AnchorButton from 'src/components/common/anchor-button';
import AppIcon from 'src/components/common/app-icon';
import StatusCircle from 'src/components/common/app-status-circle';
import TypeToConfirmModal from 'src/components/common/type-to-confirm-modal';
import logger from 'src/logger';
import {
  isGlideAndroidApp,
  isGlideIOSApp,
  isGlideMobileApp,
  isGlideTabletApp,
} from 'src/utils/browsers';
import {
  receiveMessage as receiveMobileBridgeMessage,
  sendMessage as sendMobileBridgeMessage,
} from 'src/utils/mobile-bridge';
import pusher from 'src/utils/pusher';
import textFromReactElem from 'src/utils/text-from-react-elem';
import type { AppStore } from './app-store';
import { Route } from 'src/types/proto/routes';

export const TOP_BAR_HEIGHT = '64px';
export const THEME_COLOR_BORDER_HEIGHT = '5px';

const smMql = window.matchMedia('(min-width: 768px)');

const mdMql = window.matchMedia('(min-width: 992px)');

const lgMql = window.matchMedia('(min-width: 1200px)');

// Need a breakpoint somewhere between lg and xl, so 'sxl'
// as in small extra large. As of this writing it's used
// in the document-prepare-tabs component
const sxlMql = window.matchMedia('(min-width: 1400px)');

const xlMql = window.matchMedia('(min-width: 1600px)');

const xxlMql = window.matchMedia('(min-width: 1800px)');

const notificationClassPrefix = 'app-notification';
interface DisplayDocument {
  name: string;
  url: string;
}

interface PreviewDocument {
  transaction: unknown;
  transactionDocument: unknown;
  doc: unknown;
  checklistItem?: unknown;
  titlePrefix?: string;
  noEditButton?: boolean;
}

export type UIError = {
  code?: number;
  message?: string;
};

type AddFormLibrariesModalProps = {
  visible: boolean;
  onCancel?: () => void;
  okText?: string;
};

type AssociationSearchModalProps = {
  visible: boolean;
  defaultStates?: string[];
};

type CustomModalFuncProps = {
  title?: string;
  // TODO TJ-32693: string is likely the only correct type because of app bridge call.
  content?: string | JSX.Element;
  okText?: string;
  onOk?: () => void;
  okType?: 'primary' | 'solid' | string;
  cancelText?: string;
  onCancel?: () => Promise<any> | void;
  children?: React.ReactNode;
};

export type ConfirmOptions = CustomModalFuncProps &
  Omit<ModalFuncProps, keyof CustomModalFuncProps>;

type TypeConfirmOptions = ConfirmOptions & {
  confirmString: string;
  help?: string | JSX.Element;
  hideClue: boolean;
};

export type ToastOptions = {
  message?: string | React.ReactElement;
  type?: ToastVariant;
  description?: string;
  duration?: number;
  buttonText?: string;
  placement?: string;
  onButtonClick?: () => void;
};

type Theme = {
  // eslint-disable-next-line camelcase
  logo_url: string;
  color: string;
  // eslint-disable-next-line camelcase
  form_logo_url: string;
};

type CustomModal =
  | ((props: { onClose: () => void }) => React.ReactElement)
  | React.ReactElement
  | null;

export default class UiStore {
  @observable hasTopBarStack: boolean[] = [];
  @observable themeStack: Theme[] = [];

  @observable isMobileSize = false;
  isGlideMobileApp = false;
  isGlideTabletApp = false;
  isGlideIOSApp = false;
  isGlideAndroidApp = false;
  @observable isSmallerThanMdSize = false;
  @observable isSmallerThanLgSize = false;
  @observable isSmallerThanSxlSize = false;
  @observable isSmallerThanXlSize = false;
  @observable isSmallerThanXXlSize = false;
  @observable screenWidth = 0;
  @observable screenHeight = 0;
  @observable user = null;
  @observable _loaderCnt = 0;
  @observable displayDocument: DisplayDocument | null = null;
  @observable previewDocument: PreviewDocument | null = null;
  @observable fullErrorMessage: undefined | UIError | string | null = null;
  @observable fullUIError: UIError | string | null = null;
  @observable popoverOpen = false;
  @observable.ref modalStates = {};
  @observable customModal: React.ReactElement | null = null;
  @observable customModalKey = 0;
  @observable modalStack: string[] = [];
  @observable activeModals: Record<string, () => void> = {};
  @observable hasNewRelease = false;
  @observable pageScrollTop = 0;
  @observable pageHeight = 0;
  @observable transactionPageHeight = 0;
  @observable mainContentScrollTop = 0;
  @observable transactionTopBarUnDock = 0;
  @observable pageAreaScrollLock = 0;
  @observable displayDocumentCallback: (() => void) | null = null;
  @observable isDrawerOpen = false;
  @observable addFormLibrariesModalProps: AddFormLibrariesModalProps = {
    visible: false,
    onCancel: () => {
      this.setAddFormLibrariesModalProps({
        visible: false,
      });
    },
  };
  @observable associationSearchModalProps: AssociationSearchModalProps = {
    visible: false,
  };
  @observable forceLoadForms: (() => void) | null = null;
  @observable fetchRecommendedProviders: (() => void) | null = null;
  @observable shouldTMForceLoadForms = false;

  parent: AppStore;
  smMql: MediaQueryList;
  mdMql: MediaQueryList;
  lgMql: MediaQueryList;
  sxlMql: MediaQueryList;
  xlMql: MediaQueryList;
  xxlMql: MediaQueryList;
  lastFullErrorMessage?: UIError | string | null;

  @computed
  get hamburgerToggled() {
    return this.popoverOpen;
  }

  constructor(parent: AppStore) {
    makeObservable(this);
    this.parent = parent;
    this.smMql = smMql;
    this.smMql.addListener(this.mediaQueryChanged);
    this.mdMql = mdMql;
    this.mdMql.addListener(this.mediaQueryChanged);
    this.lgMql = lgMql;
    this.lgMql.addListener(this.mediaQueryChanged);
    this.sxlMql = sxlMql;
    this.sxlMql.addListener(this.mediaQueryChanged);
    this.xlMql = xlMql;
    this.xlMql.addListener(this.mediaQueryChanged);
    this.xxlMql = xxlMql;
    this.xxlMql.addListener(this.mediaQueryChanged);
    this.mediaQueryChanged();

    this.setMainContentScroll_(document.documentElement.scrollTop);
    document.addEventListener('scroll', (event) => {
      if (event.target === document) {
        this.setMainContentScroll(document.documentElement.scrollTop);
      }
    });

    this.windowResize();
    window.addEventListener('resize', this.windowResizeThrottled);

    this.receiveCloseModalAction();
    this.isGlideMobileApp = isGlideMobileApp();
    this.isGlideTabletApp = isGlideTabletApp();
    this.isGlideIOSApp = isGlideIOSApp();
    this.isGlideAndroidApp = isGlideAndroidApp();

    configureTmModalLoader({
      onModalOpen: ({ close }) => {
        const modalId = this.registerModal(close);
        this.handleModalVisibilityChange({
          modalId,
          visible: true,
          cancelable: true,
        });

        return modalId;
      },
      onModalClose: ({ state: modalId }) => {
        this.handleModalVisibilityChange({
          modalId,
          visible: false,
        });
        this.unregisterModal(modalId);
      },
    });
  }

  @computed
  get isEmbedded() {
    return Boolean(this.parent.embeddedApp?.isEmbedded);
  }

  @computed
  get embeddedApp() {
    return this.parent.embeddedApp;
  }

  @computed
  get theme(): unknown {
    if (this.themeStack.length) {
      return this.themeStack[this.themeStack.length - 1];
    }
    if (this.parent.orgs && this.parent.orgs.org) {
      return this.parent.orgs.org.theme;
    }
    return null;
  }

  @computed
  get themeColor(): string {
    return get(this.theme, 'color');
  }

  @computed
  get themeHasColor(): boolean {
    return Boolean(this.themeColor);
  }

  @computed
  get themeColorBorderHeight(): string {
    return this.themeHasColor ? THEME_COLOR_BORDER_HEIGHT : '0px';
  }

  @computed
  get topBarHeight(): string {
    return `${parseInt(TOP_BAR_HEIGHT, 10)}px`;
  }

  @computed
  get totalTopBarHeight() {
    return `${
      parseInt(this.topBarHeight, 10) +
      parseInt(this.themeColorBorderHeight, 10)
    }px`;
  }

  @action
  setForceLoadForms(forceLoadForms: (() => void) | null) {
    this.forceLoadForms = forceLoadForms;
  }

  @action
  setFetchRecommendedProviders(fetchRecommendedProviders: (() => void) | null) {
    this.fetchRecommendedProviders = fetchRecommendedProviders;
  }

  @action
  setShouldTMForceLoadForms(value: boolean) {
    this.shouldTMForceLoadForms = value;
  }

  @action
  setAssociationSearchModalProps(modalProps: AssociationSearchModalProps) {
    this.associationSearchModalProps = modalProps;
  }

  @action
  setAddFormLibrariesModalProps(modalProps: AddFormLibrariesModalProps) {
    this.addFormLibrariesModalProps = {
      onCancel: () => {
        this.setAddFormLibrariesModalProps({ visible: false });
      },
      ...modalProps,
    };
  }

  @action
  updateSeenAddFormLibrariesModal = () => {
    if (!this.parent.account.config.seenAddFormLibrariesModal) {
      this.parent.account.updateConfig({
        seenAddFormLibrariesModal: true,
      });
    }
  };

  @action
  setTheme = (theme: Theme) => {
    this.themeStack.push(theme);
    return action(() => {
      this.themeStack.pop();
    });
  };

  @action
  setCustomModal = (el: CustomModal) => {
    let modalEl: React.ReactElement | null;
    this.customModalKey += 1;
    const key = this.customModalKey;
    if (isFunction(el)) {
      modalEl = React.createElement(el, {
        onClose: action(() => {
          if (this.customModalKey === key) {
            this.customModal = null;
          }
        }),
      });
    } else {
      modalEl = el;
    }
    this.customModal = modalEl;
  };

  @action
  resetModal = () => {
    this.modalStates = {};
  };

  @action
  setModal = (modalStates: ConfirmOptions) => {
    this.modalStates = modalStates;
  };

  @computed
  get showSpinner() {
    return this._loaderCnt > 0;
  }

  @action
  startSpinning() {
    this._loaderCnt += 1;
  }

  @action
  stopSpinning() {
    this._loaderCnt = Math.max(0, this._loaderCnt - 1);
  }

  @action
  forceStopSpinning() {
    this._loaderCnt = 0;
  }

  @action
  setPopoverOpen(popoverOpen: boolean) {
    if (popoverOpen !== this.popoverOpen) {
      this.popoverOpen = popoverOpen;
    }
  }

  @computed
  get hasTopBar(): boolean {
    if (this.hasTopBarStack.length) {
      return this.hasTopBarStack[this.hasTopBarStack.length - 1];
    }
    return true;
  }

  @action
  setHasTopBar(hasTopBar: boolean) {
    this.hasTopBarStack.push(hasTopBar);
    return action(() => {
      this.hasTopBarStack.pop();
    });
  }

  @action
  setDisplayDocument(doc: DisplayDocument, callback?: () => void | null) {
    this.displayDocument = doc;
    const { displayDocumentCallback } = this;
    if (callback) {
      this.displayDocumentCallback = callback;
    }
    if (!doc && displayDocumentCallback) {
      displayDocumentCallback();
    }
  }

  @action
  setPreviewDocument(previewDocProps: PreviewDocument) {
    /**
     * Props to open preview-document-viewer, or null
     * @param transaction - transaction object
     * @param transactionDocument - td object
     * @param doc - document object, e.g. td.document
     * @param checklistItem - checklist item object (task)
     */
    this.previewDocument = previewDocProps;
  }

  @action.bound
  handleModalVisibilityChange({
    modalId,
    visible,
    notify = true,
    ...msgProps
  }: {
    modalId?: string;
    visible?: boolean;
    notify?: boolean;
    [key: string]: any;
  }) {
    if (modalId === undefined) {
      return;
    }

    this.modalStack = this.modalStack.filter((c) => c !== modalId);
    if (visible) {
      this.modalStack.push(modalId);
    }
    if (notify) {
      const event = visible ? 'modalOpen' : 'modalClose';
      sendMobileBridgeMessage({
        data: {
          event,
          modalId,
          ...msgProps,
        },
      });
    }
  }

  isModalVisibleOnStack = (modalId?: string) => {
    if (modalId === undefined) {
      return true;
    }

    if (!this.modalStack.length) {
      return false;
    }
    return this.modalStack[this.modalStack.length - 1] === modalId;
  };

  @action
  registerModal = (closeCallback: () => void) => {
    let nextId: string;
    while (true) {
      nextId = String(Math.floor(Math.random() * 10000) + 1);
      if (!this.activeModals[nextId]) {
        this.activeModals[nextId] = closeCallback;
        return nextId;
      }
    }
  };

  @action
  unregisterModal = (modalId?: string) => {
    if (modalId === undefined) {
      return;
    }
    delete this.activeModals[modalId];
  };

  receiveCloseModalAction = () => {
    receiveMobileBridgeMessage({
      name: 'closeModal',
      cb: (modalId: string) => {
        this.processCloseModalMessage(modalId);
        return true;
      },
      permanent: true,
    });
  };

  processCloseModalMessage = (modalId: string) => {
    if (!modalId) {
      Object.keys(this.activeModals).forEach((m) => {
        if (!this.isModalVisibleOnStack(m)) {
          return;
        }

        try {
          this.activeModals[m]();
        } catch (unusedErr) {
          // ignoring error about no callback function for entry
        }
      });
    }

    // closing a particular modal identified by id
    if (modalId in this.activeModals) {
      this.activeModals[modalId]();
    }
  };

  mediaQueryChanged = () => {
    runInAction(() => {
      // Treat iPad app as mobile layout
      this.isMobileSize = !this.smMql.matches || this.isGlideTabletApp;
      this.isSmallerThanMdSize = !this.mdMql.matches;
      this.isSmallerThanLgSize = !this.lgMql.matches;
      this.isSmallerThanSxlSize = !this.sxlMql.matches;
      this.isSmallerThanXlSize = !this.xlMql.matches;
      this.isSmallerThanXXlSize = !this.xxlMql.matches;
    });
  };

  @action
  windowResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh.toFixed(4)}px`);

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  };
  windowResizeThrottled = throttle(this.windowResize, 100);

  toast = async ({
    message,
    description,
    type,
    duration,
    buttonText,
    onButtonClick,
    placement,
  }: ToastOptions) => {
    const cleanM = textFromReactElem(message);
    const cleanD = textFromReactElem(description);
    const nativeType = type === undefined ? 'info' : type;
    const showNative =
      this.isGlideMobileApp &&
      cleanM &&
      ['success', 'warning', 'error', 'warn', 'info'].includes(nativeType) &&
      !buttonText;
    if (showNative) {
      sendMobileBridgeMessage({
        data: {
          event: 'showToast',
          title: cleanM,
          message: cleanD,
          style: nativeType,
        },
      });
      return undefined;
    }
    let notifier;
    // TODO: should add in_progress to ToastVariant
    if ((type as any) === 'in_progress') {
      notifier = (config: ArgsProps) => {
        return notification.open({
          ...config,
          icon: (
            <Provider ui={this} features={this.parent.features}>
              <StatusCircle status="IN_PROGRESS" />
            </Provider>
          ),
        });
      };
    } else if (type === undefined) {
      notifier = notification.open;
    } else {
      notifier = notification[type];
    }

    const key = `open${Date.now()}`;
    notification.close(key);
    let btn;

    const closeIcon = (
      <Provider ui={this} features={this.parent.features}>
        <AppIcon
          type="feather"
          name="x"
          size={16}
          className={`${notificationClassPrefix}-button`}
        />
      </Provider>
    );

    if (buttonText) {
      const onClick = () => {
        if (onButtonClick) {
          onButtonClick();
        }
        notification.close(key);
      };
      btn = (
        <Provider ui={this} features={this.parent.features}>
          <AnchorButton
            type="primary"
            onClick={onClick}
            className={`${notificationClassPrefix}-button`}
          >
            {buttonText}
          </AnchorButton>
        </Provider>
      );
    }
    const title = (
      <span className={`${notificationClassPrefix}-title`}>{message}</span>
    );
    const detail = (
      <span className={`${notificationClassPrefix}-detail`}>{description}</span>
    );

    if (this.isEmbedded && type) {
      // Currently doesn't account for buttonText custom actions
      // TODO: will need both CAB and parent app changes to accomodate

      // Compass currently only allows for one line message, rather than title + detail
      // We will concat them together if there is a description
      const _message = `${message}${description ? `: ${description}` : ''}`;

      const promise = this.embeddedApp.addToast({
        variant: type,
        message: _message,
        duration,
        action: buttonText,
      });

      if (buttonText && onButtonClick) {
        const result = await promise;
        if (result?.actionClicked) {
          onButtonClick();
        }
      }

      return true;
    }

    notifier({
      className: notificationClassPrefix,
      key,
      message: title,
      description: detail,
      duration,
      btn,
      placement:
        !placement || placement === 'bottomRight' ? 'topRight' : placement,
      style: this.isMobileSize &&
        !this.isGlideTabletApp && {
          width: 'calc(100vw - 16px)',
        },
      closeIcon,
    });
    return key;
  };

  @action.bound
  wentWrongFull(err: UIError) {
    // This is usually cleared in didUpdateRoute()
    sendMobileBridgeMessage({
      data: {
        event: 'unrecoverableError',
      },
      dispose: false,
    });
    this.fullErrorMessage = err
      ? this.getWentWrongMessage(err)
      : 'Something went wrong.';
    this.fullUIError = err;

    // Print ui store error message for development.
    if (process.env.NODE_ENV !== 'production') {
      console.error('UiStore went wrong', err);
    }
  }

  @action.bound
  clearWentWrongFull() {
    this.fullErrorMessage = null;
    this.fullUIError = null;
  }

  @action.bound
  showNewReleaseModal(release: string) {
    if (window.Glide.RELEASE !== release) {
      logger.log(window.Glide.RELEASE, release);
      this.hasNewRelease = true;
    }
  }

  @action.bound
  setNewReleaseVersion(newRelease: string) {
    window.Glide.LATEST_RELEASE = newRelease;
  }

  @action.bound
  clearNewReleaseModal() {
    this.hasNewRelease = false;
  }

  initialize = () => {
    if (this.parent.account.isAuthenticated) {
      const channel = pusher.subscribe('product');
      channel.bind(
        'new_release',
        ({ release, force }: { release: string; force: boolean }) => {
          if (force) {
            this.showNewReleaseModal(release);
          }
          this.setNewReleaseVersion(release);
        }
      );
    }
  };

  getWentWrongMessage = (err: UIError) => {
    if (err.code === api.INVALID_ARGUMENT) {
      return err.message;
    }
    if (err.code === api.PERMISSION_DENIED) {
      return err.message || 'Permission denied.';
    }
    if (err.code === api.UNKNOWN) {
      return 'Something went wrong on the server.';
    }
    if (err.code === api.NOT_FOUND || get(err, 'response.status') === 404) {
      return err.message || "Expected something to be there that wasn't.";
    }
    if (err.code === api.FAILED_PRECONDITION) {
      return err.message || 'Something went wrong.';
    }
    if (err.code === api.UNAVAILABLE) {
      return err.message || 'Something went wrong.';
    }
    return 'Something went wrong.';
  };

  wentWrong = (err?: unknown) => {
    if (!err) {
      this.toast({
        message: 'Something went wrong.',
        type: 'error',
      });
      return;
    }

    this.toast({
      message: this.getWentWrongMessage(err),
      type: 'error',
    });

    logger.error(err);
  };

  sendNativeConfirm = (options: ConfirmOptions) => {
    const content =
      typeof options.content === 'string' ? options.content : null;
    const title = typeof options.title === 'string' ? options.title : 'Confirm';
    const actions = [
      {
        primary: true,
        type: options.okType ? options.okType : 'primary',
        label: options.okText ? options.okText : 'OK',
        handler: 'primary',
      },
      {
        label: options.cancelText ? options.cancelText : 'Cancel',
        handler: 'secondary',
      },
    ];
    sendMobileBridgeMessage({
      name: 'mobileapp',
      dispose: false,
      data: {
        event: 'confirmOpen',
        title,
        message: content,
        actions,
      },
    });
  };

  sendBridgeConfirm = (options: ConfirmOptions) => {
    const modalConfig = {
      header: {
        title: options.title,
      },
      body: options.content,
      isOpen: true,
      destroyOnClose: true,
      footer: {
        primaryButton: {
          text: options.okText || 'Ok',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick: options.onOk ? options.onOk : () => {},
          variant: options.okType || 'solid',
        },
        secondaryButton: {
          text: options.cancelText || 'Cancel',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick: options.onCancel ? options.onCancel : () => {},
          variant: 'enclosed',
        },
      },
    };
    // TODO: it would be defined a type named ModalConfig better in EmbeddedAppStore while someone convert EmbeddedAppStore to ts.
    return this.embeddedApp.triggerModal(modalConfig as any);
  };

  confirm = (
    options: ConfirmOptions,
    method: ModalFuncProps['type'] = 'confirm'
  ) => {
    if (this.isEmbedded) {
      return this.sendBridgeConfirm(options);
    }
    if (!this.isGlideMobileApp) {
      return Modal[method](options);
    }
    this.sendNativeConfirm(options);
    const actionHandlers = {
      primary: options.onOk ? options.onOk : () => {},
      secondary: options.onCancel ? options.onCancel : () => {},
    };
    return receiveMobileBridgeMessage({
      name: 'confirmAction',
      cb: (name: 'primary' | 'secondary') => {
        const handler = actionHandlers[name];
        handler();
      },
    });
  };

  typeToConfirm = (options: TypeConfirmOptions) => {
    if (this.isEmbedded) {
      return this.sendBridgeConfirm(options);
    }
    if (this.isGlideMobileApp) {
      // eslint-disable-next-line no-restricted-globals
      return confirm(options as any);
    }
    return this.setCustomModal(({ onClose }) => {
      const onClose_ = options.onCancel
        ? async () => {
            if (options.onCancel) {
              await options.onCancel();
            }
            onClose();
          }
        : onClose;
      return <TypeToConfirmModal onClose={onClose_} {...options} />;
    });
  };

  typeToConfirmAsync = (options: TypeConfirmOptions): Promise<boolean> => {
    if (this.isGlideMobileApp) {
      return this.confirmAsync(options);
    }
    return new Promise((resolve) => {
      return this.typeToConfirm({
        ...options,
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  confirmAsync = async (
    options: ConfirmOptions,
    method: ModalFuncProps['type'] = 'confirm'
  ): Promise<boolean> => {
    if (this.isEmbedded) {
      return new Promise((resolve) => {
        return this.sendBridgeConfirm({
          ...options,
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        });
      });
    }
    if (!this.isGlideMobileApp) {
      return new Promise((resolve) => {
        return Modal[method](
          Object.assign(options, {
            onOk: () => {
              resolve(true);
            },
            onCancel: () => {
              resolve(false);
            },
          })
        );
      });
    }
    this.sendNativeConfirm(options);
    return new Promise((resolve) => {
      const asyncCheck = (handler: string) => {
        if (handler === 'primary') {
          resolve(true);
          return;
        }
        resolve(false);
      };
      receiveMobileBridgeMessage({
        name: 'confirmAction',
        cb: asyncCheck,
      });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  didUpdateRoute = (to: Route, from: Route) => {
    if (
      this.fullErrorMessage &&
      this.fullErrorMessage === this.lastFullErrorMessage
    ) {
      this.clearWentWrongFull();
    }

    if (this.popoverOpen) {
      this.setPopoverOpen(false);
    }

    this.lastFullErrorMessage = this.fullErrorMessage;

    if (this.isDrawerOpen) {
      this.setDrawerOpen(false);
    }
  };

  setMainContentScroll_ = (scrollTop: number) => {
    runInAction(() => {
      this.mainContentScrollTop = scrollTop;
      document.documentElement.style.setProperty(
        '--scrollTop',
        `${scrollTop.toFixed(4)}px`
      );
    });
  };

  @action setMainContentScroll = throttle(this.setMainContentScroll_, 50);

  @computed
  get transactionsTopBarDocked() {
    return (
      !this.transactionTopBarUnDock &&
      this.mainContentScrollTop >= parseInt(this.totalTopBarHeight, 10)
    );
  }

  @action
  unDockTransactionTopBar = () => {
    this.transactionTopBarUnDock = Math.max(
      this.transactionTopBarUnDock + 1,
      1
    );
  };

  @action
  dockTransactionTopBar = () => {
    this.transactionTopBarUnDock = Math.max(
      this.transactionTopBarUnDock - 1,
      0
    );
  };

  @computed
  get pageAreaScrollLocked() {
    return Boolean(this.pageAreaScrollLock);
  }

  updateDocumentOverflowStyle = () => {
    if (document.documentElement) {
      document.documentElement.style.overflow = this.pageAreaScrollLocked
        ? 'hidden'
        : 'inherit';
    }
  };

  @action
  lockPageAreaScroll = () => {
    this.pageAreaScrollLock = Math.max(this.pageAreaScrollLock + 1, 1);
    this.updateDocumentOverflowStyle();
  };

  @action
  unlockPageAreaScroll = () => {
    this.pageAreaScrollLock = Math.max(this.pageAreaScrollLock - 1, 0);
    this.updateDocumentOverflowStyle();
  };

  @action
  setDrawerOpen = (status: boolean) => {
    this.isDrawerOpen = status;
  };
}
