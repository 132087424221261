

import React, { Component } from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import * as featherIcons from 'react-feather';
import DynamicAntdIcon from 'src/components/common/dynamic-antd-icon';

const clsPrefix = 'app-icon';

const _FEATHER_ICON_DEFAULT_SIZE = 18;
const _SVG_ICON_DEFAULT_SIZE = 18;
const _CX_SVG_ICON_DEFAULT_SIZE = 16;
export const types = ['feather', 'antd', 'svg', 'cx'];

@inject((stores) => {
  return {
    features: stores.features ?? {},
  };
})
export default class AppIcon extends Component {
  static propTypes = {
    type: PropTypes.oneOf(types),
    name: PropTypes.string,
    svg: PropTypes.any,
    importSvg: PropTypes.func,
    size: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string,
    alt: PropTypes.string,
    style: PropTypes.object,
    features: PropTypes.object.isRequired,
  };

  static defaultProps = {
    type: 'feather',
  };

  state = {
    svgLoaded: false,
  };

  get FeatherComponent() {
    const { type, name } = this.props;
    const caseInsensitiveName =
      !!name &&
      Object.keys(featherIcons).find(
        (k) => k.toLowerCase() === name.replace('-', '').toLowerCase()
      );
    return type === 'feather' ? featherIcons[caseInsensitiveName] : undefined;
  }

  loadSvg = async () => {
    const { svg, importSvg } = this.props;
    if (!svg) {
      const module = await importSvg();
      this.svgModule = module.default || module;
      if (!this._unmounted) {
        this.setState({
          svgLoaded: true,
        });
      }
    }
  };

  get Svg() {
    return this.props.svg || this.svgModule;
  }

  componentDidMount() {
    const { type, svg } = this.props;
    if (type === 'svg' && !svg) {
      this.loadSvg();
    }
    this._unmounted = false;
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  render() {
    const {
      type,
      name,
      size,
      width,
      className,
      alt,
      style,
      features: unusedFeatures,
      svg: unusedSvg,
      importSvg: unusedImportSvg,
      ...otherProps
    } = this.props;
    const { svgLoaded } = this.state;

    noop(svgLoaded); // svgLoaded state value is currently only used to trigger re-render when it's set to true

    const FeatherComponent = this.FeatherComponent;

    if (type === 'feather' && !!FeatherComponent) {
      return (
        <FeatherComponent
          className={classNames(clsPrefix, `${clsPrefix}--feather`, className)}
          size={size || _FEATHER_ICON_DEFAULT_SIZE}
          style={style || {}}
          {...otherProps}
        />
      );
    }
    if (type === 'antd') {
      return (
        <DynamicAntdIcon
          className={classNames(clsPrefix, `${clsPrefix}--antd`, className)}
          style={{
            ...(style || {}),
            ...(size
              ? {
                  fontSize: `${size}px`,
                }
              : {}),
          }}
          type={name}
          {...otherProps}
        />
      );
    }
    if (type === 'svg' && !!this.Svg) {
      if (isFunction(this.Svg)) {
        // SVG imported as JSX component
        const Svg = this.Svg;
        return (
          <Svg
            className={classNames(clsPrefix, `${clsPrefix}--svg`, className)}
            width={size || _SVG_ICON_DEFAULT_SIZE}
            height={size || _SVG_ICON_DEFAULT_SIZE}
            style={style || {}}
            {...otherProps}
          />
        );
      }
      // SVG imported as file (img)
      return (
        <img
          className={classNames(clsPrefix, `${clsPrefix}--svg`, className)}
          src={this.Svg}
          alt={alt || name}
          width={width || size || _SVG_ICON_DEFAULT_SIZE}
          style={{
            ...(style || {}),
          }}
          {...otherProps}
        />
      );
    }
    if (type === 'cx') {
      // cx-icon system
      // the default size is not always 16.
      // Please refer to https://github.com/UrbanCompass/uc-frontend/tree/master/packages/cx-icons#resizing
      // For icon examples, please refer to https://example-apps.compass.com/cx-icons/4.33.0/
      const iconSize = size ?? _CX_SVG_ICON_DEFAULT_SIZE;
      return (
        <svg
          className={`cx-icon ${className}`}
          width={iconSize}
          height={iconSize}
          viewBox={`0 0 ${iconSize} ${iconSize}`}
          style={style || {}}
          tabIndex={otherProps.tabIndex}
        >
          <use
            xlinkHref={`/assets/ucfe-assets/cx-icons/4/cx-icons.cdn.svg#${name}`}
          />
        </svg>
      );
    }

    return null;
  }
}
