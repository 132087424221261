/* Generated file, please DO NOT edit directly */
/* See jsapp/proto2ts.sh */

/* eslint-disable */
import type { PermissionEnum } from './permissions';

export const protobufPackage = 'forms';

export interface Range {
  min: number;
  max: number;
}

export interface StringChoice {
  value: string;
  label: string;
  icon: string;
  help: string;
  inlineHelp: string;
  noteLabel: string;
  notePlaceholder: string;
  requireNote: boolean;
  triggerNote: boolean;
  tooltip: string;
  disabled: boolean;
  warning: string;
  inputType: string;
}

export interface ListItem {
  key: string;
  label: string;
  num: string;
  help: string;
  noteLabel: string;
  notePlaceholder: string;
  maxNoteLength: number;
  valuesRequireNote: string[];
  valuesTriggerNote: string[];
  disabledValues: string[];
}

export interface ValueLabel {
  value: string;
  label: string;
}

export interface IntegerChoice {
  value: number;
  label: string;
}

export interface FieldValidation {
  kind: string;
  required?: FieldValidationRequired;
  string?: FieldValidationString;
  integer?: FieldValidationInteger;
  boolean?: FieldValidationBoolean;
  address?: FieldValidationAddress;
  fullName?: FieldValidationFullName;
  party?: FieldValidationParty;
  stringList?: FieldValidationStringList;
  stringChoiceNote?: FieldValidationStringChoiceNote;
  stringChoiceOther?: FieldValidationStringChoiceOther;
  stringListChoiceOther?: FieldValidationStringListChoiceOther;
  inspectionReports?: FieldValidationInspectionReports;
  note?: FieldValidationNote;
  message?: FieldValidationMessage;
  emailList?: FieldValidationEmailList;
  recipients?: FieldValidationRecipients;
  recipientsNameOptional?: FieldValidationRecipients;
  date?: FieldValidationDateMessage;
  reAgent?: FieldValidationReAgent;
  json?: FieldValidationJson;
  stringListChoice2?: FieldValidationStringListChoice2;
  datetime?: FieldValidationDatetime;
  boolean2?: FieldValidationBoolean2;
  avidProperty?: FieldValidationAvidProperty;
  avidProperty2?: FieldValidationAvidProperty2;
  avidProperty3?: FieldValidationAvidProperty3;
  avidProperty4?: FieldValidationAvidProperty4;
  additionalWorks?: FieldValidationAdditionalWorks;
  yourAdjacentProperty?: FieldValidationYourAdjacentProperty;
  truthyRequired?: FieldValidationTruthyRequired;
  pennsylvaniaAdditions?: FieldValidationPennsylvaniaAdditions;
  listOthers?: FieldValidationListOthers;
  minorPunchList?: FieldValidationMinorPunchList;
  paymentList?: FieldValidationPaymentList;
  multiStringListChoice?: FieldValidationMultiStringListChoice;
  spdAddendumSection?: FieldValidationSPDAddendumSection;
  contact?: FieldValidationContact;
  url?: FieldValidationUrl;
  yearMakeModelHeaters?: FieldValidationYearMakeModelHeaters;
  recurringFee?: FieldValidationRecurringFee;
  signature?: FieldValidationSignature;
  timestamp?: FieldValidationTimestamp;
  fillConfigSpecifications?: FieldValidationFillConfigSpecifications;
}

/** Standard Validations */
export interface FieldValidationRequired {}

export interface FieldValidationString {
  range?: Range;
  choices: StringChoice[];
  strip: boolean;
}

export interface FieldValidationInteger {
  range?: Range;
  choices: IntegerChoice[];
}

export interface FieldValidationBoolean {}

export interface FieldValidationAddress {}

export interface FieldValidationFullName {}

export interface FieldValidationDateMessage {}

export interface FieldValidationParty {}

export interface FieldValidationContact {}

export interface FieldValidationRecurringFee {}

export interface FieldValidationStringList {
  unique: boolean;
  choices: StringChoice[];
}

export interface FieldValidationStringChoiceOther {
  choices: StringChoice[];
}

export interface FieldValidationStringChoiceNote {
  choices: StringChoice[];
  requireNotes: string[];
  triggerNotes: string[];
  other: boolean;
}

export interface FieldValidationStringListChoiceOther {
  choices: StringChoice[];
}

export interface FieldValidationStringListChoice2 {
  choices: StringChoice[];
  other: boolean;
  requireNote: boolean;
  noneChoice: boolean;
  otherLabel: string;
}

export interface FieldValidationMultiStringListChoice {
  items: ListItem[];
  choices: StringChoice[];
}

export interface FieldValidationInspectionReports {}

export interface FieldValidationAdditionalWorks {}

export interface FieldValidationPennsylvaniaAdditions {}

export interface FieldValidationSPDAddendumSection {}

export interface FieldValidationYearMakeModelHeaters {
  limit: number;
}

export interface FieldValidationPaymentList {}

export interface FieldValidationListOthers {}

export interface FieldValidationMinorPunchList {}

export interface FieldValidationYourAdjacentProperty {}

export interface FieldValidationNote {}

export interface FieldValidationMessage {}

export interface FieldValidationEmailList {}

export interface FieldValidationRecipients {}

export interface FieldValidationRecipientsNameOptional {}

export interface FieldValidationReAgent {}

export interface FieldValidationJson {}

export interface FieldValidationDatetime {}

export interface FieldValidationBoolean2 {}

export interface FieldValidationAvidProperty {}

export interface FieldValidationAvidProperty2 {}

export interface FieldValidationAvidProperty3 {}

export interface FieldValidationAvidProperty4 {}

export interface FieldValidationTruthyRequired {}

export interface FieldValidationUrl {}

export interface FieldValidationSignature {
  maxLength: number;
}

export interface FieldValidationTimestamp {}

export interface FieldValidationFillConfigSpecifications {}

export interface Condition {
  terms: string[];
  rule: string;
  /** A default value to be used if this condition is applicable */
  value?: any;
}

export interface FillCondition {
  terms: string[];
  rule: string;
  level: FillConditionErrLevel;
  message: string;
  id: string;
}

export const FillConditionErrLevel = {
  WARNING: 'WARNING',
  ERROR: 'ERROR',
} as const;

export type FillConditionErrLevel =
  typeof FillConditionErrLevel[keyof typeof FillConditionErrLevel];

export interface ConditionalLink {
  linkNamespace: string;
  linkId: string;
  terms: string[];
  rule: string;
}

export interface Field {
  id: string;
  kind: string;
  label: string;
  help: string;
  synonym: string;
  pdfFormat: string;
  pdfDefault: string;
  strFormat: string;
  htmlFormat: string;
  cond: string;
  num: string;
  optional: boolean;
  validations: FieldValidation[];
  description: string;
  inlineHelp: string;
  inlineHelpExpanded: boolean;
  /** If this is set to true, no field outputs will be added to the form. */
  noOutput: boolean;
  overflowPdfFormat: string;
  params?: { [key: string]: any };
  longNum: string;
  /** Deprecated? What you might be looking for is Condition.value */
  naValue: string;
  linkId: string;
  linkNamespace: string;
  ownerId: string;
  permissions: PermissionEnum[];
  condition?: Condition;
  /**
   * This means the field doesn't have a user input which has significance for
   * checks that ensure all user
   * inputs are mapped to forms
   */
  noInput: boolean;
  fillConditions: FillCondition[];
  defaultValue?: any;
  conditionalLinking: ConditionalLink[];
}

export interface OcrField {
  ocrEnabled: boolean;
  ocrTerm: string;
  offsetTop: number;
  offsetBottom: number;
  offsetLeft: number;
  offsetRight: number;
}

export interface Attachment {
  tdId: string;
  url: string;
  filename: string;
  byteSize: number;
  updateHeaderFooter: boolean;
  data: Uint8Array;
  merge: boolean;
}

export interface FieldImport {
  id: string;
  alias: string;
}

export interface Output {
  id: string;
  strFormat: string;
  htmlFormat: string;
  pdfFormat: string;
  /**
   * If this is set to true, the PdfRenderer will avoid directly
   * rendering this output.
   */
  noOutput: boolean;
  overflowPdfFormat: string;
  imports: FieldImport[];
  handler: string;
  params?: { [key: string]: any };
  outKind: string;
  label: string;
  na: boolean;
}

export interface Extension {
  path: string;
}

export interface Form {
  version: string;
  fields: Field[];
  outputs: Output[];
  extensions: Extension[];
}

export interface Annotation {
  kind: AnnotationKind;
  id: string;
  fieldId: string;
  page: number;
  left: number;
  top: number;
  height: number;
  width: number;
  exportValue?: any;
  /** used for multipart fields, currently multiline text fields */
  fieldPart: number;
  font: string;
  fontSize: number;
  fontColor: string;
  recipientRole: string;
  formState: string;
  horizontalAlign: string;
  name: string;
  canWrap: boolean;
  /** used for tabs, they can be optional */
  optional: boolean;
  source: string;
  recipientColor: string;
  /**
   * deprecated. It was used as the default value for fields
   * can't be removed as some forms have the fields
   * default_value stored in here
   */
  defaultValue?: any;
  ocrField?: OcrField;
}

export const AnnotationKind = {
  UNKNOWN: 'UNKNOWN',
  SIGNATURE_TAB: 'SIGNATURE_TAB',
  INITIALS_TAB: 'INITIALS_TAB',
  DATE_TAB: 'DATE_TAB',
  TEXT: 'TEXT',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  SIGNATURE_ZONE: 'SIGNATURE_ZONE',
  INITIALS_ZONE: 'INITIALS_ZONE',
  DATE_ZONE: 'DATE_ZONE',
  TEXT_ZONE: 'TEXT_ZONE',
  CHECKBOX_ZONE: 'CHECKBOX_ZONE',
  SECTION_ZONE: 'SECTION_ZONE',
  PDF_STAMP: 'PDF_STAMP',
  PDF_TEXT: 'PDF_TEXT',
  PDF_REDACT_AREA: 'PDF_REDACT_AREA',
  PDF_REDACT_TEXT: 'PDF_REDACT_TEXT',
  DROPDOWN: 'DROPDOWN',
  FILESTACK_IMAGE: 'FILESTACK_IMAGE',
  ADDENDUM_FIELD: 'ADDENDUM_FIELD',
  PDF_CHECKBOX: 'PDF_CHECKBOX',
  PDF_STRIKEOUT_TEXT: 'PDF_STRIKEOUT_TEXT',
  PDF_STRIKEOUT_LINE: 'PDF_STRIKEOUT_LINE',
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  COMPANY: 'COMPANY',
  TITLE: 'TITLE',
  ENTITY_NAME: 'ENTITY_NAME',
  LICENSE: 'LICENSE',
} as const;

export type AnnotationKind = typeof AnnotationKind[keyof typeof AnnotationKind];

export interface AnnotationList {
  data: Annotation[];
}

export interface Signature {
  text: string;
  kind: SignatureKind;
}

/** font, image, options etc */
export const SignatureKind = {
  UNKNOWN: 'UNKNOWN',
  SIGNATURE: 'SIGNATURE',
  INITIALS: 'INITIALS',
} as const;

export type SignatureKind = typeof SignatureKind[keyof typeof SignatureKind];

export interface FormConfig {
  formId: string;
  items: FormConfigItem[];
}

export interface FormConfigItem {
  num: string;
  title: string;
  description: string;
  /** link to annotation.field_id, sync with annotation_id */
  fieldId: string;
  outlineExclusive: boolean;
  /** link to annotation.id */
  annotationId: string;
  helpText: string;
  /** used internally in form-builder, link to item.id */
  id: string;
  kind: FormConfigItemKind;
  legalDescription?: FormConfigItemLegalDescription;
  optional: boolean;
  /** @deprecated use title for question field */
  OutdatedQuestion: string;
  /** @deprecated never used */
  OutdatedItem?: FormConfigItem;
  /** kind: SECTION */
  section?: FormConfigItemSection;
  /** kind: TERM */
  term?: FormConfigItemTerm;
  /** kind: BOOLEAN */
  boolean?: FormConfigItemBoolean;
  /** kind: STRING_CHOICE */
  stringChoice?: FormConfigItemStringChoice;
  /** kind: DATE */
  date?: FormConfigItemStringOption;
  /** kind: TITLE_PAGE */
  titlePage?: FormConfigItemTitlePage;
  /** kind: STRING */
  string?: FormConfigItemStringOption;
  /** kind: DYNAMIC_EXPLANATION */
  dynamicExplanation?: FormConfigItemDynamicExplanation;
  /** kind: MULTI_SELECT */
  multiSelect?: FormConfigItemStringChoice;
  /** kind: TIME */
  time?: FormConfigItemStringOption;
  /** kind: ADDRESS */
  address?: FormConfigItemStringOption;
  /** kind: PARTY */
  party?: FormConfigItemStringOption;
  /** kind: LEGAL_DISCLAIMER */
  legalDisclaimer?: FormConfigItemLegalDisclaimer;
  /** kind: PAGE_BREAK */
  pageBreak?: FormConfigItemPageBreak;
  /** kind: CURRENCY */
  currency?: FormConfigItemCurrency;
  /** kind: NUMERIC */
  numeric?: FormConfigItemNumeric;
  /** kind: PHONE_NUMBER */
  phoneNumber?: FormConfigItemStringOption;
}

export const FormConfigItemKind = {
  UNKNOWN: 'UNKNOWN',
  /** SECTION - FormBuilder component: Section */
  SECTION: 'SECTION',
  /** TERM - FormBuilder component: Term */
  TERM: 'TERM',
  /** PAGE_TITLE - FormBuilder component: Title page */
  PAGE_TITLE: 'PAGE_TITLE',
  /** PAGE_BREAK - FormBuilder component: Page break */
  PAGE_BREAK: 'PAGE_BREAK',
  /** BOOLEAN - FormBuilder component: Single checkbox */
  BOOLEAN: 'BOOLEAN',
  /** STRING - FormBuilder component: Short answer | Long answer */
  STRING: 'STRING',
  /** DATE - FormBuilder component: Short answer | Long answer */
  DATE: 'DATE',
  /** TIME - FormBuilder component: Short answer | Long answer */
  TIME: 'TIME',
  /** MULTIPLE_SELECT - FormBuilder component: Multi-checkbox */
  MULTIPLE_SELECT: 'MULTIPLE_SELECT',
  /** ADDRESS - FormBuilder component: Short answer | Long answer */
  ADDRESS: 'ADDRESS',
  /** STRING_CHOICE - FormBuilder component: Radio group */
  STRING_CHOICE: 'STRING_CHOICE',
  /** DESCRIPTION - FormBuilder component: Description */
  DESCRIPTION: 'DESCRIPTION',
  /** DYNAMIC_EXPLANATION - FormBuilder component: Dynamic explanation */
  DYNAMIC_EXPLANATION: 'DYNAMIC_EXPLANATION',
  /** PARTY_FULL_NAME - FormBuilder component: Party Full Name (not currently in use) */
  PARTY_FULL_NAME: 'PARTY_FULL_NAME',
  /** PARTY - FormBuilder component: Short answer | Long answer */
  PARTY: 'PARTY',
  /** LEGAL_DISCLAIMER - FormBuilder component: Legal Disclaimer */
  LEGAL_DISCLAIMER: 'LEGAL_DISCLAIMER',
  /** CURRENCY - FormBuilder component: Currency */
  CURRENCY: 'CURRENCY',
  /** NUMERIC - FormBuilder component: Numeric */
  NUMERIC: 'NUMERIC',
  /** PHONE_NUMBER - FormBuilder component: Short answer | Long answer */
  PHONE_NUMBER: 'PHONE_NUMBER',
  /** _OUTDATED_SINGLE_SELECT - @deprecated replaced by STRING_CHOICE */
  _OUTDATED_SINGLE_SELECT: '_OUTDATED_SINGLE_SELECT',
} as const;

export type FormConfigItemKind =
  typeof FormConfigItemKind[keyof typeof FormConfigItemKind];

export interface FormConfigItemSection {
  items: FormConfigItem[];
}

export interface FormConfigItemTerm {
  items: FormConfigItem[];
}

export interface FormConfigItemPageBreak {
  items: FormConfigItem[];
}

export interface FormConfigItemLegalDisclaimer {
  header: string;
  description: string;
  disclaimer: string;
  footer: string;
}

export interface FormConfigItemTitlePage {
  subTitle: string;
  image: string;
}

export interface FormConfigItemStringOption {
  variant: FormConfigItemStringOptionStringVariant;
  textLabel: string;
  needAttachment: boolean;
}

export const FormConfigItemStringOptionStringVariant = {
  UNKNOWN: 'UNKNOWN',
  SHORT_ANSWER: 'SHORT_ANSWER',
  LONG_ANSWER: 'LONG_ANSWER',
} as const;

export type FormConfigItemStringOptionStringVariant =
  typeof FormConfigItemStringOptionStringVariant[keyof typeof FormConfigItemStringOptionStringVariant];

export interface FormConfigItemBoolean {
  isCheckbox: boolean;
  yesLabel: string;
  yesValue: string;
  noLabel: string;
  noValue: string;
  needAttachment: boolean;
}

export interface FormConfigItemStringChoice {
  options: FormConfigItemStringChoiceStringChoiceOption[];
}

export interface FormConfigItemStringChoiceStringChoiceOption {
  /** link to annotation.export_value, sync with annotation.id */
  value: string;
  label: string;
  triggerNote: boolean;
  requireNote: boolean;
  noteLabel: string;
  /** link to radio / checkbox option, annotation.id */
  annotationId: string;
  /** link to text annotation.id */
  textAnnotationId: string;
  helpText: string;
  isOther: boolean;
  /** link to annotation.field_id, sync with annotation.id */
  fieldId: string;
}

export interface FormConfigItemDynamicExplanation {
  links: FormConfigItemDynamicExplanationStringChoiceLink[];
  textLabel: string;
}

export interface FormConfigItemDynamicExplanationStringChoiceLink {
  fieldId: string;
  choices: string[];
  requireNote: boolean;
  isAdditional: boolean;
  /** used internally in form-builder, link to item.id */
  nodeId: string;
}

export interface FormConfigItemLegalDescription {
  description: string;
  title: string;
}

export interface FormConfigItemCurrency {
  isPercentageEditable: boolean;
  percentageBoundFieldId: string;
}

export interface FormConfigItemNumeric {
  hasMin: boolean;
  minValue: number;
  hasMax: boolean;
  maxValue: number;
  kind: FormConfigItemNumericNumericKind;
}

export const FormConfigItemNumericNumericKind = {
  INTEGER: 'INTEGER',
  DECIMAL: 'DECIMAL',
} as const;

export type FormConfigItemNumericNumericKind =
  typeof FormConfigItemNumericNumericKind[keyof typeof FormConfigItemNumericNumericKind];

export interface FormOutline {
  terms: FormOutlineTerm[];
  subforms: { [key: string]: string };
}

export interface FormOutlineTerm {
  subformId: string;
  number: string;
  kind: FormOutlineTermKind;
  fieldIds: { [key: string]: string };
  title: string;
  description: string;
  validations: FormOutlineTermValidation[];
  section?: FormOutlineTermSection;
  combo?: FormOutlineTermCombo;
  stringFormat?: FormOutlineTermStringFormat;
  boolean?: FormOutlineTermBoolean;
  singleSelect?: FormOutlineTermSingleSelect;
  multipleSelect?: FormOutlineTermMultipleSelect;
  datetime?: FormOutlineTermDatetime;
  relativeDate?: FormOutlineTermRelativeDate;
  mutuallyExclusive?: FormOutlineTermMutuallyExclusive;
}

export const FormOutlineTermKind = {
  UNKNOWN: 'UNKNOWN',
  SECTION: 'SECTION',
  COMBO: 'COMBO',
  STRING_FORMAT: 'STRING_FORMAT',
  BOOLEAN: 'BOOLEAN',
  SINGLE_SELECT: 'SINGLE_SELECT',
  MULTIPLE_SELECT: 'MULTIPLE_SELECT',
  DATETIME: 'DATETIME',
  RELATIVE_DATE: 'RELATIVE_DATE',
  MUTUALLY_EXCLUSIVE: 'MUTUALLY_EXCLUSIVE',
} as const;

export type FormOutlineTermKind =
  typeof FormOutlineTermKind[keyof typeof FormOutlineTermKind];

export const FormOutlineTermValidation = {
  UNKNOWN_VALIDATION: 'UNKNOWN_VALIDATION',
  REQUIRED: 'REQUIRED',
} as const;

export type FormOutlineTermValidation =
  typeof FormOutlineTermValidation[keyof typeof FormOutlineTermValidation];

export interface FormOutlineTermFieldIdsEntry {
  key: string;
  value: string;
}

export interface FormOutlineTermSection {
  terms: FormOutlineTerm[];
}

export interface FormOutlineTermCombo {
  terms: FormOutlineTermComboComboTerm[];
}

export interface FormOutlineTermComboComboTerm {
  term?: FormOutlineTerm;
  key: string;
  isUnsetExpression: string;
  isNaExpression: string;
  isVisibleExpression: string;
}

export interface FormOutlineTermStringFormat {
  placeholder: string;
  multiline?: FormOutlineTermStringFormatMultiline;
  disableDropdown: boolean;
}

export interface FormOutlineTermStringFormatMultiline {
  min: number;
  max: number;
}

export interface FormOutlineTermBoolean {
  radio: boolean;
  checkbox: boolean;
  yes?: FormOutlineTermBooleanChoice;
  no?: FormOutlineTermBooleanChoice;
  negated: boolean;
  oppositeCheckbox: boolean;
  breakLines: boolean;
}

export interface FormOutlineTermBooleanChoice {
  label: string;
  extra: boolean;
  extraLabel: string;
  extraPlaceholder: string;
  extraMultiline?: FormOutlineTermStringFormatMultiline;
}

export interface FormOutlineTermSingleSelect {
  radio: boolean;
  labels: { [key: string]: string };
  extra: { [key: string]: FormOutlineTermSingleSelectExtra };
  none?: FormOutlineTermSingleSelectNoneChoice;
  allowClear: boolean;
  ignore: string[];
}

export interface FormOutlineTermSingleSelectExtra {
  available: boolean;
  required: boolean;
  placeholder: string;
}

export interface FormOutlineTermSingleSelectNoneChoice {
  allow: boolean;
  label: string;
}

export interface FormOutlineTermSingleSelectLabelsEntry {
  key: string;
  value: string;
}

export interface FormOutlineTermSingleSelectExtraEntry {
  key: string;
  value?: FormOutlineTermSingleSelectExtra;
}

export interface FormOutlineTermMultipleSelect {
  choices: FormOutlineTermMultipleSelectChoice[];
  select: boolean;
  checkboxes: boolean;
  allowClear: boolean;
  sideBySide: boolean;
  placeholder: string;
}

export interface FormOutlineTermMultipleSelectChoice {
  fieldKey: string;
  label: string;
  description: string;
  extra?: FormOutlineTermSingleSelectExtra;
}

export interface FormOutlineTermDatetime {
  timeOnly: boolean;
  period?: FormOutlineTermDatetimePeriod;
  timePlaceholder: string;
  datePlaceholder: string;
}

export interface FormOutlineTermDatetimePeriod {
  radio: boolean;
  amValue: string;
  pmValue: string;
}

export interface FormOutlineTermRelativeDate {
  relativeDescription: string;
  radio?: FormOutlineTermRelativeDateRadio;
}

export interface FormOutlineTermRelativeDateRadio {
  hasRadio: boolean;
  fixedDateValue: string;
  relativeValue: string;
}

export interface FormOutlineTermMutuallyExclusive {
  labels: { [key: string]: string };
}

export interface FormOutlineTermMutuallyExclusiveLabelsEntry {
  key: string;
  value: string;
}

export interface FormOutlineSubformsEntry {
  key: string;
  value: string;
}
